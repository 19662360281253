import React, { useCallback } from "react";
import { Csku, Typography } from "@commonsku/styles";
import styled from "styled-components";
import { SummaryValues, SUMMARY_VALUE_TYPES } from "./types";

const ItemContainer = styled(Csku)`
  height: 100%;
  padding: 10px 16px !important;
  display: flex;
  flex-direction: column;
  min-width: fit-content;
`;

const ItemValueContainer = styled(Csku)`
  display: flex;
  flex-wrap: nowrap;
`;

interface SummaryItemProps {
  label: string;
  data: string[];
  type: SummaryValues[];
}

const SummaryItem = ({ label, data, type }: SummaryItemProps) => {
  const renderData = useCallback(
    () => (
      <ItemValueContainer as={"div"}>
        {data.map((value, index) =>
          type[index] === SUMMARY_VALUE_TYPES.Percentage && data.length > 1 ? (
            <Typography.Text
              key={value}
              color="var(--color-black)"
              fontSize="14px"
              lineHeight="24px"
            >
              &nbsp; &nbsp; ({value})
            </Typography.Text>
          ) : (
            <Typography.Text
              key={value}
              color="var(--color-black)"
              fontSize="16px"
              lineHeight="24px"
            >
              {value}
            </Typography.Text>
          ),
        )}
      </ItemValueContainer>
    ),
    [data, type],
  );

  return (
    <ItemContainer as={"div"}>
      <Typography.Text
        as={"div"}
        bold
        color="var(--color-neutrals-darkest)"
        lineHeight="24px"
      >
        {label}
      </Typography.Text>
      {renderData()}
    </ItemContainer>
  );
};

export default SummaryItem;

import React, { useMemo } from "react";
import { colors } from "@commonsku/styles";
import { CellProps as ReactTableCellProps } from "react-table";
import {
  BASE_COLUMN_TYPES,
  BaseColumnType,
  CELL_REGISTRY,
  TextOptions,
} from "../config/types";
import loadable from "@loadable/component";
import { ReportColumnConfig, ReportRow } from "../config/ReportColumnConfig";
import { useReportContext } from "../../report_context";

interface BaseCellProps<D extends object = {}, V = any>
  extends Pick<ReactTableCellProps<D, V>, "column" | "row" | "value"> {}

export interface CellProps<T extends BaseColumnType, V = any>
  extends Omit<BaseCellProps<{}, V>, "column"> {
  column: BaseCellProps<{}, V>["column"] & ReportColumnConfig<T>;
  style?: React.CSSProperties;
  className?: string;
  textStyle?: Omit<TextOptions, "textTransform" | "textColor">;
  onClick?: () => void;
}

const CellComponent = ({
  type,
  ...props
}: { type: BaseColumnType } & CellProps<typeof type>) => {
  const { setSubReportType, setActiveRow } = useReportContext();
  const LoadedCellComponent = useMemo(
    () =>
      loadable(() => import(`../components/${CELL_REGISTRY[type]}`), {
        cacheKey: () => type,
      }),
    [type],
  );

  const column = props.column as unknown as ReportColumnConfig<typeof type>;
  const {
    subReportType,
    justify,
    textOptions: { textColor, textTransform, truncate = false },
  } = column;

  const textStyle = useMemo(() => {
    const result: React.CSSProperties = {};
    if (textTransform !== undefined) {
      result.textTransform = textTransform;
    }

    if (truncate) {
      result.display = "-webkit-box";
      result.overflow = "hidden";
      result.textOverflow = "ellipsis";
      result.WebkitBoxOrient = "vertical";
      result.WebkitLineClamp = 2;
      result.whiteSpace = "normal";
    }

    return result;
  }, [textTransform, truncate]);

  const color = useMemo(() => {
    switch (type) {
      case BASE_COLUMN_TYPES.Link:
        return "var(--color-primary1-main)";
      default:
        return colors.neutrals.bodyText;
    }
  }, [type]);

  const handleOpenSubReport = useMemo(() => {
    if (!subReportType || !props.row) {
      return null;
    }
    return () => {
      setSubReportType(subReportType);
      setActiveRow(props.row.original as unknown as ReportRow);
    };
  }, [subReportType, props.row]);

  const cellStyle = useMemo(
    () => ({
      color: textColor || color,
      cursor: handleOpenSubReport ? "pointer" : undefined,
    }),
    [color, textColor],
  );

  return (
    <LoadedCellComponent
      {...props}
      onClick={handleOpenSubReport}
      style={cellStyle}
      textStyle={textStyle}
      className={`report-cell cell-${justify}`}
    />
  );
};

const Cell =
  <T extends BaseColumnType, V = any>(type: BaseColumnType) =>
  (props: CellProps<T, V>) => <CellComponent type={type} {...props} />;

export default Cell;

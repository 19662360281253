import { EReportTypes } from "../../routes/report_types";
import {
  SubReportConfigBase,
  SubReportFilter,
  SubReportFilterSources,
} from "../../sub-report/types";

const currYear = new Date().getFullYear();

const defaultFilters = [
  {
    field: "client_id",
    source: {
      type: SubReportFilterSources.Row,
      columnName: "client",
    },
  },
  {
    field: "order_type",
    source: {
      type: SubReportFilterSources.Value,
      value: "INVOICE",
    },
  },
  {
    field: "active",
    source: {
      type: SubReportFilterSources.Value,
      value: "1",
    },
  },
];

const InvoiceSubReportY1: SubReportConfigBase = {
  type: EReportTypes.DashboardInvoiceY1,
  tabTitle: `${currYear}`,
  defaultFilters: [
    {
      field: "from_date_orderdate",
      source: {
        type: SubReportFilterSources.Value,
	value: `${currYear}-01-01 00:00:00`,
      },
    },
    {
      field: "to_date_orderdate",
      source: {
        type: SubReportFilterSources.Value,
	value: `${currYear}-12-31 23:59:59`,
      },
    },
    ...defaultFilters,
  ],
};

const InvoiceSubReportY2: SubReportConfigBase = {
  type: EReportTypes.DashboardInvoiceY1,
  tabTitle: `${currYear - 1}`,
  defaultFilters: [
    {
      field: "from_date_orderdate",
      source: {
        type: SubReportFilterSources.Value,
	value: `${currYear - 1}-01-01 00:00:00`,
      },
    },
    {
      field: "to_date_orderdate",
      source: {
        type: SubReportFilterSources.Value,
	value: `${currYear - 1}-12-31 23:59:59`,
      },
    },
    ...defaultFilters,
  ],
};

const InvoiceSubReportY3: SubReportConfigBase = {
  type: EReportTypes.DashboardInvoiceY1,
  tabTitle: `${currYear - 2}`,
  defaultFilters: [
    {
      field: "from_date_orderdate",
      source: {
        type: SubReportFilterSources.Value,
	value: `${currYear - 2}-01-01 00:00:00`,
      },
    },
    {
      field: "to_date_orderdate",
      source: {
        type: SubReportFilterSources.Value,
	value: `${currYear - 2}-12-31 23:59:59`,
      },
    },
    ...defaultFilters,
  ],
};

const InvoiceSubReportY4: SubReportConfigBase = {
  type: EReportTypes.DashboardInvoiceY1,
  tabTitle: `${currYear - 3}`,
  defaultFilters: [
    {
      field: "from_date_orderdate",
      source: {
        type: SubReportFilterSources.Value,
	value: `${currYear - 3}-01-01 00:00:00`,
      },
    },
    {
      field: "to_date_orderdate",
      source: {
        type: SubReportFilterSources.Value,
	value: `${currYear - 3}-12-31 23:59:59`,
      },
    },
    ...defaultFilters,
  ],
};

export const SubReports: SubReportConfigBase[] = [
  InvoiceSubReportY1,
  InvoiceSubReportY2,
  InvoiceSubReportY3,
  InvoiceSubReportY4,
];

import React from "react";
import { BulkAction, BulkActionConfigBase } from "../index";
import { dataToUrlParams, downloadCsv, oauth } from "../../../../utils";

export function prepareLabel<
  T extends Record<string, unknown> = Record<string, unknown>
>(selected: T[]): React.ReactNode {
  if (selected.length === 0) {
    return "Select one or more commissions";
  }
  let label = '';
  const paid = selected.filter((row: any) => row.commission_id?.commission_paid === 1);
  const unpaid = selected.filter((row: any) => row.commission_id?.commission_paid === 0);
  if (paid.length > 0) {
    label += `${paid.length} paid commission${paid.length === 1 ? "" : "s"} selected`;
  }
  if (unpaid.length > 0) {
    if (paid.length > 0) {
      label += ".  ";
    }
    label += `${unpaid.length} unpaid commission${unpaid.length === 1 ? "" : "s"} selected`;
  }
  return label;
};

export const PayAction: BulkAction = {
  label: "Pay",
  execute: async (selected) => {
    const commissionIds = selected.filter(
      (row: any) => row.commission_id?.commission_paid === 0
    ).map(
      (row: any) => row.commission_id?.commission_id
    );
    if (commissionIds.length > 0) {
      const data = {
        id: commissionIds.join(","),
        commission_paid: 1,
        date_commission_paid: (new Date()).toISOString().slice(0,10)
      };
      await oauth("PUT", "commission?" + dataToUrlParams(data));
      downloadCsv(
        "report/commission?" + dataToUrlParams({ commission_ids: commissionIds }),
        "commissions.csv"
      );
      return `${commissionIds.length} commission${commissionIds.length !== 1 ? "s" : ""} marked paid`;
    } else {
      throw "No payable commissions selected";
    }
  },
  isActive: (selected) => selected.some((row: any) => row.commission_id?.commission_paid === 0),
  capabilities: ["MODIFY-COMMISSION"],
  refreshOnSuccess: true,
};

export const UnPayAction: BulkAction = {
  label: <>Un&#8209;Pay</>,
  execute: async (selected) => {
    const commissionIds = selected.filter(
      (row: any) => row.commission_id?.commission_paid === 1
    ).map(
      (row: any) => row.commission_id?.commission_id
    );
    if (commissionIds.length > 0) {
      await oauth("POST", "commission", { action: "unpay", id: commissionIds });
      return `${commissionIds.length} commission${commissionIds.length !== 1 ? "s" : ""} marked unpaid`;
    } else {
      throw "No paid commissions selected";
    }
  },
  isActive: (selected) => selected.some((row: any) => row.commission_id?.commission_paid === 1),
  capabilities: ["MODIFY-COMMISSION"],
  refreshOnSuccess: true,
};

export const ExportAction: BulkAction = {
  label: "Export",
  execute: async (selected) => {
    const commissionIds = selected.filter(
      (row: any) => row.commission_id?.commission_paid === 1
    ).map(
      (row: any) => row.commission_id?.commission_id
    );
    if (commissionIds.length > 0) {
      downloadCsv(
        "report/commission?" + dataToUrlParams({ commission_ids: commissionIds }),
        "commissions.csv"
      );
      return `${commissionIds.length} commission${commissionIds.length !== 1 ? "s" : ""} exported`;
    } else {
      throw "No paid commissions selected";
    }
  },
  isActive: (selected) => selected.some((row: any) => row.commission_id?.commission_paid === 1),
  capabilities: ["EXPORT-REPORT"],
};

export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    PayAction,
    UnPayAction,
    ExportAction,
  ]
};

import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { EReportTypes } from "../../routes/report_types";

const RepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id",
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 100,
  avatarOptions: { withFullName: true },
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

const SalesOrderQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "SOs",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_sales_orders"],
  accessorKey: "num_sales_orders",
  canSort: true,
  orderByField: "num_sales_orders",
  width: 50,
  dataTransform: ({ num_sales_orders }) => ({
    amount: num_sales_orders,
  }),
  subReportType: EReportTypes.DashboardSalesOrder,
};

const InvoiceQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "INVs",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_invoices"],
  accessorKey: "num_invoices",
  canSort: true,
  orderByField: "num_invoices",
  width: 50,
  dataTransform: ({ num_invoices }) => ({
    amount: num_invoices,
  }),
  subReportType: EReportTypes.DashboardInvoice,
};

const PurchaseOrderQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "POs",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_purchase_orders"],
  accessorKey: "num_purchase_orders",
  canSort: true,
  orderByField: "num_purchase_orders",
  width: 50,
  dataTransform: ({ num_purchase_orders }) => ({
    amount: num_purchase_orders,
  }),
  subReportType: EReportTypes.DashboardPurchaseOrder,
};

const SalesOrderTotalColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Sales Orders",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_sales_orders"],
  accessorKey: "total_sales_orders",
  canSort: true,
  orderByField: "total_sales_orders",
  moneyOptions: { decimalPlaces: 2 },
  width: 75,
  dataTransform: ({ total_sales_orders, currency_id }) => ({
    amount: total_sales_orders,
    currency_id,
  }),
  subReportType: EReportTypes.DashboardSalesOrder,
};

const InProductionTotalColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "In Production",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_in_production"],
  accessorKey: "total_in_production",
  canSort: true,
  orderByField: "total_in_production",
  moneyOptions: { decimalPlaces: 2 },
  width: 75,
  dataTransform: ({ total_in_production, currency_id }) => ({
    amount: total_in_production,
    currency_id,
  }),
  subReportType: EReportTypes.DashboardInProduction,
};

const InvoiceTotalColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Invoices",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_invoices"],
  accessorKey: "total_invoices",
  canSort: true,
  orderByField: "total_invoices",
  moneyOptions: { decimalPlaces: 2 },
  width: 75,
  dataTransform: ({ total_invoices, currency_id }) => ({
    amount: total_invoices,
    currency_id,
  }),
  subReportType: EReportTypes.DashboardInvoice,
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  RepColumn,
  SalesOrderQuantityColumn,
  InvoiceQuantityColumn,
  PurchaseOrderQuantityColumn,
  SalesOrderTotalColumn,
  InProductionTotalColumn,
  InvoiceTotalColumn,
];

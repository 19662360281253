import { ReportSummaryConfigBase } from "../../summary/types";

export const Summary: ReportSummaryConfigBase[] = [
  {
    label: "Invoice Total",
    fields: ["margin_subtotal"],
    type: ["MONEY"],
    options: [{ decimalPlaces: 2 }],
  },
  {
    label: "Gross Margin Total",
    fields: ["total_margin_amount"],
    type: ["MONEY"],
    options: [{ decimalPlaces: 2 }],
  },
  {
    label: "Gross Margin Average",
    fields: ["average_margin_amount", "average_margin"],
    type: ["MONEY", "PERCENTAGE"],
    options: [{ decimalPlaces: 2 }],
  },
  {
    label: "Sales Rep Commission Total",
    fields: ["total_commission"],
    type: ["MONEY"],
    options: [{ decimalPlaces: 2 }],
  },
  {
    label: "Number of Rows",
    fields: ["row_count"],
    type: ["COUNT"],
  },
];

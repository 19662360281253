import React from "react";
import styled from "styled-components";
import { Csku, SkubotSpinner } from "@commonsku/styles";

import { useReportContext } from "./report_context";
import { STYLE_CONSTANTS } from "./config/styles";

const { TABLE_PAGE_HEADER_HEIGHT, FILTER_CONTAINER_HEIGHT, SIDE_BAR_WIDTH } =
  STYLE_CONSTANTS;

const LoadingContainer = styled(Csku)<{ height?: number }>`
  position: absolute;
  top: 250px;
  left: ${SIDE_BAR_WIDTH}px;
  width: calc(100vw - ${SIDE_BAR_WIDTH}px - 8px);
  height: ${({ height }) =>
    height
      ? height
      : `calc(100vh - ${TABLE_PAGE_HEADER_HEIGHT}px - ${FILTER_CONTAINER_HEIGHT}px - 10px)`};
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  z-index: 2;

  div {
    position: absolute;
    left: 45%;
    top: 10%;
  }
`;

const Loading: React.FC = () => {
  const { loading, tableHeight } = useReportContext();

  return loading ? (
    // 200; added to account for the padding on the top of the table
    <LoadingContainer height={tableHeight + 200}>
      <SkubotSpinner />
    </LoadingContainer>
  ) : null;
};

export default Loading;

import { BASE_COLUMN_TYPES } from "../columns/config/types";
import { ReportColumnConfigBase } from "../columns/config/ReportColumnConfig";

export const OpportunityNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Opportunity",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number", "form_number"],
  accessorKey: "opportunity_number",
  canSort: true,
  orderByField: "form_number",
  width: 83,
  dataTransform: ({ job_number, form_number }) => ({
    href: `/project/${job_number}`,
    text: form_number,
  }),
};

export const PresentationNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Presentation #",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number", "form_number"],
  accessorKey: "presentation_number",
  canSort: true,
  orderByField: "form_number",
  width: 83,
  dataTransform: ({ job_number, form_number }) => ({
    href: `/project/${job_number}/presentation/${form_number}`,
    text: form_number,
  }),
};

export const EstimateNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Estimate #",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number", "form_number"],
  accessorKey: "estimate_number",
  canSort: true,
  orderByField: "form_number",
  width: 83,
  dataTransform: ({ job_number, form_number }) => ({
    href: `/project/${job_number}/estimate/${form_number}`,
    text: form_number,
  }),
};

export const SalesOrderNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Sales Order #",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number", "form_number"],
  accessorKey: "sales_order_number",
  canSort: true,
  orderByField: "form_number",
  width: 83,
  dataTransform: ({ job_number, form_number }) => ({
    href: `/project/${job_number}/sales-order/${form_number}`,
    text: form_number,
  }),
};

export const InvoiceNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Invoice #",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number", "form_number"],
  accessorKey: "invoice_number",
  canSort: true,
  orderByField: "form_number",
  width: 83,
  dataTransform: ({ job_number, form_number }) => ({
    href: `/project/${job_number}/invoice/${form_number}`,
    text: form_number,
  }),
};

export const PurchaseOrderNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "PO #",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number", "form_number"],
  accessorKey: "purchase_order_number",
  canSort: true,
  orderByField: "form_number",
  width: 83,
  dataTransform: ({ job_number, form_number }) => ({
    href: `/project/${job_number}/production`,
    text: form_number,
  }),
};

export const ClientColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Client",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["client_id", "client_name"],
  accessorKey: "client",
  canSort: true,
  orderByField: "client_name",
  width: 250,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({ client_id, client_name }) => ({
    href: `/client.php?id=${client_id}`,
    text: client_name,
  }),
  // linkAction: ({ client_id }) => console.log(client_id), // change to open clientSidePanel
};

export const ParentClientColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Client",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["parent_id", "parent_name"],
  accessorKey: "client",
  canSort: true,
  orderByField: "parent_name",
  width: 250,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({ parent_id, parent_name }) => ({
    href: `/parent.php?id=${parent_id}`,
    text: parent_name,
  }),
  // linkAction: ({ client_id }) => console.log(client_id), // change to open clientSidePanel
};

export const SupplierColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Supplier Name",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["division_name", "supplier_id", "division_id"],
  accessorKey: "division_name",
  canSort: true,
  orderByField: "division_name",
  width: 344,
  dataTransform: ({ division_name, supplier_id, division_id }) => ({
    text: division_name,
    href: `/vendor.php?id=${supplier_id}&division_id=${division_id}`,
  }),
};

export const ProjectColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Project",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number", "job_name"],
  accessorKey: "job_number",
  canSort: true,
  orderByField: "job_number",
  width: 250,
  dataTransform: ({ job_number, job_name }) => ({
    href: `/project/${job_number}`,
    text: `${job_number}: ${job_name}`,
  }),
};

export const BudgetColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Budget",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_budget", "currency_id"],
  accessorKey: "budget",
  canSort: true,
  orderByField: "total_budget",
  width: 95,
  moneyOptions: { decimalPlaces: 0 },
  dataTransform: ({ total_budget, currency_id }) => ({
    amount: total_budget,
    currency_id,
  }),
};

export const SubtotalColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Subtotal",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_subtotal", "currency_id"],
  accessorKey: "subtotal",
  canSort: true,
  orderByField: "total_subtotal",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ total_subtotal, currency_id }) => ({
    amount: total_subtotal,
    currency_id,
  }),
};

export const TotalColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Total",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_total", "currency_id"],
  accessorKey: "total",
  canSort: true,
  orderByField: "total_total",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ total_total, currency_id }) => ({
    amount: total_total,
    currency_id,
  }),
};

export const OrderDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Order Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_orderdate"],
  accessorKey: "date_orderdate",
  canSort: true,
  orderByField: "date_orderdate",
  width: 120,
};

export const DateCreatedColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Date Created",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_created"],
  accessorKey: "date_created",
  canSort: true,
  orderByField: "date_created",
  width: 120,
};

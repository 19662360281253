import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const DashboardPurchaseOrderReportConfig: ReportConfigBase = {
  type: EReportTypes.DashboardPurchaseOrder,
  endpoint: "purchaseorder",
  reportKey: "purchase_orders",
  filtersBase: [],
  summaryBase: [],
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Dashboard Purchase Order Report provides detailed information about a rep's purchase orders",
    url: "https://help.commonsku.com/knowledge/sales-dashboard-report",
  },
};

export default DashboardPurchaseOrderReportConfig;

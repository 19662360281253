import { StyleProps } from "../config/types";

class SwitchStyleProps implements StyleProps {
  private static readonly PADDING: number = 16; // Padding on each side
  private static readonly FONT_SIZE: number = 18; // Base font size to estimate width, using monospaced font

  public readonly options: (string | { label: string; value: string })[];
  flexBasis: number;
  flexGrow: number;
  flexShrink: number;

  constructor(options: (string | { label: string; value: string })[] = []) {
    if (options.length < 2) {
      throw new Error("Switch must have at least two options");
    }
    this.options = options;
    this.flexBasis = this.calculateWidth();
    this.flexGrow = 0;
    this.flexShrink = 0;
  }

  private calculateWidth(): number {
    return this.options
      .map((op) => (typeof op === "string" ? op : op.label))
      .reduce((total, op) => {
        const optionWidth =
          op.length * SwitchStyleProps.FONT_SIZE + SwitchStyleProps.PADDING * 2;
        return +total + optionWidth;
      }, 0);
  }

  public getStyleProps(): StyleProps {
    return {
      flexBasis: this.flexBasis,
      flexGrow: this.flexGrow,
      flexShrink: this.flexShrink,
    };
  }
}

export default SwitchStyleProps;

import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { SubReports } from "./sub-reports";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const SalesActivityReportConfig: ReportConfigBase = {
  type: EReportTypes.SalesActivity,
  endpoint: "report/sales-activity",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Sales Activity Report offers an overview of notes, calls, and meetings for yourself or your team.",
    url: "https://help.commonsku.com/knowledge/sales-dashboard-report",
  },
  subReports: {
    configs: SubReports,
    titleTemplate: (rowData: Record<string, any>) => "Rep Summary",
  },
};

export default SalesActivityReportConfig;

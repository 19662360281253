import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { EReportTypes } from "../../routes/report_types";

const RepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id",
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 100,
  avatarOptions: { withFullName: true },
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

const NewAccountQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "New Accounts",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_new_accounts"],
  accessorKey: "num_new_accounts",
  canSort: true,
  orderByField: "num_new_accounts",
  width: 50,
  dataTransform: ({ num_new_accounts }) => ({
    amount: num_new_accounts,
  }),
  subReportType: EReportTypes.DashboardAccount,
};

const OpportunityQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "Opportunities",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_opportunities"],
  accessorKey: "num_opportunities",
  canSort: true,
  orderByField: "num_opportunities",
  width: 50,
  dataTransform: ({ num_opportunities }) => ({
    amount: num_opportunities,
  }),
  subReportType: EReportTypes.DashboardOpportunity,
};

const PresentationQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "Presentations",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_presentations"],
  accessorKey: "num_presentations",
  canSort: true,
  orderByField: "num_presentations",
  width: 50,
  dataTransform: ({ num_presentations }) => ({
    amount: num_presentations,
  }),
  subReportType: EReportTypes.DashboardPresentation,
};

const EstimateQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "Estimates",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_estimates"],
  accessorKey: "num_estimates",
  canSort: true,
  orderByField: "num_estimates",
  width: 50,
  dataTransform: ({ num_estimates }) => ({
    amount: num_estimates,
  }),
  subReportType: EReportTypes.DashboardEstimate,
};

const NoteQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "Notes",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_notes"],
  accessorKey: "num_notes",
  canSort: true,
  orderByField: "num_notes",
  width: 50,
  dataTransform: ({ num_notes }) => ({
    amount: num_notes,
  }),
  subReportType: EReportTypes.DashboardNote,
};

const CallQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "Calls",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_calls"],
  accessorKey: "num_calls",
  canSort: true,
  orderByField: "num_calls",
  width: 50,
  dataTransform: ({ num_calls }) => ({
    amount: num_calls,
  }),
  subReportType: EReportTypes.DashboardCall,
};

const MeetingQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "Meetings",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_meetings"],
  accessorKey: "num_meetings",
  canSort: true,
  orderByField: "num_meetings",
  width: 50,
  dataTransform: ({ num_meetings }) => ({
    amount: num_meetings,
  }),
  subReportType: EReportTypes.DashboardMeeting,
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  RepColumn,
  NewAccountQuantityColumn,
  OpportunityQuantityColumn,
  PresentationQuantityColumn,
  EstimateQuantityColumn,
  NoteQuantityColumn,
  CallQuantityColumn,
  MeetingQuantityColumn,
];

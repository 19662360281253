import { FILTER_TYPES, FilterTypeValues, StyleProps } from "../config/types";

export const FILTER_COMPONENT_REGISTRY: Record<FilterTypeValues, string> = {
  [FILTER_TYPES.Query]: "QueryInput",
  [FILTER_TYPES.User]: "UserSelect",
  [FILTER_TYPES.Client]: "ClientSelect",
  [FILTER_TYPES.Supplier]: "SupplierSelect",
  [FILTER_TYPES.SupplierTag]: "SupplierTagSelect",
  [FILTER_TYPES.AccountStatus]: "AccountStatusSelect",
  [FILTER_TYPES.CompareTo]: "CompareToSelect",
  [FILTER_TYPES.OrderStatus]: "OrderStatusSelect",
  [FILTER_TYPES.FormType]: "FormTypeSelect",
  [FILTER_TYPES.OrderTag]: "OrderTagSelect",
  [FILTER_TYPES.ClientTag]: "ClientTagSelect",
  [FILTER_TYPES.BillingAddress]: "BillingAddressSelect",
  [FILTER_TYPES.ShippingAddress]: "ShippingAddressSelect",
  [FILTER_TYPES.ClientContact]: "ClientContactSelect",
  [FILTER_TYPES.Industry]: "IndustrySelect",
  [FILTER_TYPES.EventType]: "EventTypeSelect",
  [FILTER_TYPES.Date]: "QueryInput",
  [FILTER_TYPES.Dropdown]: "Dropdown",
  [FILTER_TYPES.Switch]: "Switch",
  [FILTER_TYPES.DateRange]: "DateRangePicker",
  [FILTER_TYPES.Priority]: "PrioritySelect",
  [FILTER_TYPES.Country]: "CountrySelect",
  [FILTER_TYPES.Province]: "ProvinceSelect",
  [FILTER_TYPES.Department]: "DepartmentSelect",
  [FILTER_TYPES.ContactTag]: "ContactTagSelect",
  [FILTER_TYPES.ClientPageProjectQuery]: "ClientPageProjectQueryInput",
  [FILTER_TYPES.Checkbox]: "Checkbox",
  [FILTER_TYPES.GenericSelect]: "GenericSelect",
} as const;

const GENERAL_DROPDOWN_STYLE_PROPS: StyleProps = {
  flexBasis: 160,
  flexShrink: 0,
  flexGrow: 0,
} as const;

export const TYPE_TO_STYLE_PROPS: Record<
  Exclude<FilterTypeValues, typeof FILTER_TYPES.Switch>,
  StyleProps
> = {
  [FILTER_TYPES.Query]: {
    flexBasis: 280,
    flexShrink: 0,
    flexGrow: 0,
  },
  [FILTER_TYPES.User]: {
    flexBasis: 200,
    flexShrink: 0,
    flexGrow: 0,
  },
  [FILTER_TYPES.Client]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.Supplier]: {
    flexBasis: 300,
    flexShrink: 0,
    flexGrow: 0,
  },
  [FILTER_TYPES.SupplierTag]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.AccountStatus]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.CompareTo]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.OrderStatus]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.FormType]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.OrderTag]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.ClientTag]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.BillingAddress]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.ShippingAddress]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.ClientContact]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.Industry]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.EventType]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.Date]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.Dropdown]: {
    flexBasis: 160,
    flexShrink: 0,
    flexGrow: 0,
  },
  [FILTER_TYPES.DateRange]: {
    flexBasis: 240,
    flexShrink: 0,
    flexGrow: 0,
  },
  [FILTER_TYPES.Priority]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.Country]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.Province]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.Department]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.ContactTag]: GENERAL_DROPDOWN_STYLE_PROPS,
  [FILTER_TYPES.ClientPageProjectQuery]: {
    flexBasis: 250,
    flexShrink: 1,
    flexGrow: 0,
  },
  [FILTER_TYPES.Checkbox]: {
    flexBasis: 160,
    flexShrink: 0,
    flexGrow: 0,
  },
  [FILTER_TYPES.GenericSelect]: GENERAL_DROPDOWN_STYLE_PROPS,
} as const;

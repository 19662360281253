import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Supplier",
    fields: "supplier_id",
    type: FILTER_TYPES.Supplier,
  },
  {
    label: "Supplier Tag",
    fields: "supplier_tag_id",
    type: FILTER_TYPES.SupplierTag,
  },
  {
    label: "Supplier Status",
    fields: "account_status_id",
    type: FILTER_TYPES.AccountStatus,
    dropdownOptionsFilter: {
      company_type: "SUPPLIER",
    },
  },
  {
    label: "Interval",
    type: FILTER_TYPES.Switch,
    defaultValue: "M",
    fields: "interval",
    options: [
      { label: "Month", value: "M" },
      { label: "Quarter", value: "Q" },
      { label: "Year", value: "Y" },
    ],
    flexBasis: 250,
    containerStyle: {
      minWidth: "250px",
    },
  },
  {
    label: "Proration",
    fields: "proration",
    type: FILTER_TYPES.Switch,
    defaultValue: "1",
    options: [
      { label: "To Date", value: "1" },
      { label: "Full", value: "2" },
    ],
    flexBasis: 160,
    containerStyle: {
      minWidth: "160px",
    },
  },
  {
    label: "Compare To",
    fields: "compare_to",
    type: FILTER_TYPES.CompareTo,
    dependsOn: { filterFields: ["interval"] },
  },
];

import { EReportTypes } from "../../routes/report_types";
import {
  SubReportConfigBase,
  SubReportFilter,
  SubReportFilterSources,
} from "../../sub-report/types";

const defaultFilters: SubReportFilter[] = [
  {
    field: "from_date_orderdate",
    source: {
      type: SubReportFilterSources.Summary,
      label: "Dates",
      index: 0,
    },
  },
  {
    field: "to_date_orderdate",
    source: {
      type: SubReportFilterSources.Summary,
      label: "Dates",
      index: 1,
    },
  },
  {
    field: "sales_rep_id",
    source: {
      type: SubReportFilterSources.Row,
      columnName: "sales_rep",
    },
  },
  {
    field: "sales_rep_type",
    source: {
      type: SubReportFilterSources.ParentQuery,
    },
  },
  {
    field: "order_status",
    source: {
      type: SubReportFilterSources.Value,
      value: "Open",
    },
  },
  {
    field: "active",
    source: {
      type: SubReportFilterSources.Value,
      value: "1",
    },
  },
];

const OpportunitySubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardOpportunity,
  tabTitle: "Opportunities",
  defaultFilters: [
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "OPPORTUNITY",
      },
    },
    ...defaultFilters,
  ],
};

const PresentationSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardPresentation,
  tabTitle: "Presentations",
  defaultFilters: [
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "PRESENTATION",
      },
    },
    ...defaultFilters,
  ],
};

const EstimateSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardEstimate,
  tabTitle: "Estimates",
  defaultFilters: [
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "ESTIMATE",
      },
    },
    ...defaultFilters,
  ],
};

export const SubReports: SubReportConfigBase[] = [
  OpportunitySubReport,
  PresentationSubReport,
  EstimateSubReport,
];

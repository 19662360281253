import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Rep Type",
    type: FILTER_TYPES.Switch,
    defaultValue: 'client_rep_id',
    fields: "group_by_type",
    options: [
      { label: 'Client Rep', value: 'client_rep_id' },
      { label: 'Order Rep', value: 'order_rep_id' },
    ],
    flexBasis: 250,
    containerStyle: {
      minWidth: '250px',
    },
  },
  {
    label: "Interval",
    type: FILTER_TYPES.Switch,
    defaultValue: 'M',
    fields: "interval",
    options: [
      { label: 'Month', value: 'M' },
      { label: 'Quarter', value: 'Q' },
      { label: 'Year', value: 'Y' },
    ],
    flexBasis: 250,
    containerStyle: {
      minWidth: '250px',
    },
  },
  {
    label: "Proration",
    fields: "proration",
    type: FILTER_TYPES.Switch,
    defaultValue: '1',
    options: [
      { label: 'To Date', value: '1' },
      { label: 'Full', value: '2' },
    ],
    flexBasis: 160,
    containerStyle: {
      minWidth: '160px',
    },
  },
  {
    label: "Compare To",
    fields: "compare_to",
    type: FILTER_TYPES.CompareTo,
    dependsOn: { filterFields: ["interval"], },
    flexBasis: 400,
    containerStyle: {
      minWidth: '400px',
    },
  },
];

import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";

const BillNumberColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> =
  {
    header: "Bill #",
    type: BASE_COLUMN_TYPES.Link,
    fields: ["bill_reference_number", "bill_id", "job_number"],
    accessorKey: "bill_reference_number",
    canSort: true,
    orderByField: "bill_reference_number",
    width: 83,
    dataTransform: ({ bill_reference_number, bill_id, job_number }) => ({
      href: `/project/${job_number}/bills/${bill_id}`,
      text: bill_reference_number,
    }),
  };

const SupplierColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Supplier",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["supplier_id", "division_id", "division_name"],
  accessorKey: "supplier",
  canSort: true,
  orderByField: "division_name",
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ supplier_id, division_id, division_name }) => ({
    href: `/vendor.php?id=${supplier_id}&division_id=${division_id}`,
    text: division_name,
  }),
};

const SubtotalColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Subtotal",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["bill_subtotal", "default_currency_id"],
  accessorKey: "bill_subtotal",
  canSort: true,
  orderByField: "bill_subtotal",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ bill_subtotal, default_currency_id }) => ({
    amount: bill_subtotal,
    currency_id: default_currency_id,
  }),
};

const ExcludedCostsColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Excluded Costs",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["excluded_costs", "default_currency_id"],
  accessorKey: "excluded_costs",
  canSort: true,
  orderByField: "excluded_costs",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ excluded_costs, default_currency_id }) => ({
    amount: excluded_costs,
    currency_id: default_currency_id,
  }),
};

const MarginCostsColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Margin Costs",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["margin_costs", "default_currency_id"],
  accessorKey: "margin_costs",
  canSort: true,
  orderByField: "margin_costs",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ margin_costs, default_currency_id }) => ({
    amount: margin_costs,
    currency_id: default_currency_id,
  }),
};

const BillReceivedDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Date Received",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["bill_date_received"],
  accessorKey: "bill_date_received",
  canSort: true,
  orderByField: "bill_date_received",
  width: 120,
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  BillNumberColumn,
  SupplierColumn,
  SubtotalColumn,
  ExcludedCostsColumn,
  MarginCostsColumn,
  BillReceivedDateColumn,
];

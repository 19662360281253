import React from "react";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { MarketingStatusIcon } from "@commonsku/styles";

const ClientColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Client",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["client_id", "client_name"],
  accessorKey: "client",
  canSort: true,
  orderByField: "client_name",
  width: 136,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({ client_id, client_name }) => ({
    href: `/client.php?id=${client_id}`,
    text: client_name,
  }),
};

const CNameColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Contact Name",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["company_contact_id", "user_first_name", "user_last_name"],
  accessorKey: "contact",
  canSort: true,
  orderByField: "user_first_name",
  width: 136,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({ company_contact_id, user_first_name, user_last_name }) => ({
    href: `/contact/${company_contact_id}`,
    text: `${user_first_name} ${user_last_name}`,
  }),
};

const AddressColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Address",
  type: BASE_COLUMN_TYPES.Text,
  fields: [
    "address_line_1",
    "address_line_2",
    "address_postal",
    "address_state",
    "address_city",
    "address_country",
    "address_name",
  ],
  accessorKey: "address",
  canSort: true,
  orderByField: "address_company",
  width: 136,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({
    address_line_1,
    address_line_2,
    address_postal,
    address_state,
    address_city,
    address_country,
    address_name,
  }) => ({
    tooltipText: `${address_line_1 ?? ""}\n
    ${address_line_2 ?? ""}\n
    ${address_city ?? ""}${address_city && address_state && ", "}${
      address_state ?? ""
    } ${address_postal ?? ""}\n
    ${address_country ?? ""}`,
    text: address_name ? address_name : "Unnamed",
  }),
};

const PhoneColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Phone",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["phone_number", "phone_extension"],
  accessorKey: "phone",
  canSort: true,
  orderByField: "phone_number",
  width: 136,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({ phone_number, phone_extension }) => ({
    text: phone_number
      ? phone_extension
        ? `${phone_number} x${phone_extension}`
        : `${phone_number}`
      : "",
  }),
};

const FaxColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Fax",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["fax_number", "fax_extension"],
  accessorKey: "fax",
  canSort: true,
  orderByField: "fax_number",
  width: 136,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({ fax_number, fax_extension }) => ({
    text: fax_number
      ? fax_extension
        ? `${fax_number} x${fax_extension}`
        : `${fax_number}`
      : "",
  }),
};

const EmailColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Email",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["user_email"],
  accessorKey: "email",
  canSort: true,
  orderByField: "user_email",
  width: 136,
  dataTransform: ({ user_email }) => ({
    href: `mailto:${user_email}`,
    text: user_email,
  }),
};

const MarketingColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "Marketing",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["marketing"],
  accessorKey: "marketing",
  canSort: true,
  orderByField: "marketing",
  width: 136,
  dataTransform: ({ marketing }) => ({
    cell: <MarketingStatusIcon approved={marketing === "Yes"} />,
  }),
};

const SRepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id",
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 73,
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

const IndustryColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Industry",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["industry_name"],
  accessorKey: "industry",
  canSort: true,
  orderByField: "industry_name",
  width: 136,
  textOptions: {
    truncate: true,
  },
};

const RevenueColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Revenue",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["revenue"],
  accessorKey: "revenue",
  canSort: true,
  orderByField: "revenue",
  width: 136,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({ revenue }) => ({
    text: revenue === null ? "--" : revenue,
  }),
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ClientColumn,
  CNameColumn,
  AddressColumn,
  PhoneColumn,
  FaxColumn,
  EmailColumn,
  MarketingColumn,
  SRepColumn,
  IndustryColumn,
  RevenueColumn,
];

import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const RepComparativeReportConfig: ReportConfigBase = {
  type: EReportTypes.RepComparative,
  endpoint: "report/rep-comparative",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The comparative rep report measures the amount based on your invoices. Learn more about how to pull a comparative report.",
    url: "https://help.commonsku.com/knowledge/rep-comparative-report",
  },
};

export default RepComparativeReportConfig;

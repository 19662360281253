import { dateRangePickerFields } from "../../filters/components/DateRangePicker";
import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Payment",
    type: FILTER_TYPES.Switch,
    defaultValue: "",
    fields: "deposit_received",
    options: [
      { label: "All", value: "" },
      { label: "Paid", value: "true" },
      { label: "Not Paid", value: "false" },
    ],
    flexBasis: 250,
    containerStyle: {
      minWidth: "250px"
    }
  },
  {
    label: "Order Date",
    type: FILTER_TYPES.DateRange,
    fields: dateRangePickerFields,
    defaultValue: ["this_year", "", ""],
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Order Rep",
    type: FILTER_TYPES.User,
    fields: ["sales_rep_id", "sales_rep_teams", "include_inactive_order_reps"],
  },
  {
    label: "Project",
    fields: "job_name_or_number",
    type: FILTER_TYPES.Query,
    fetchReportOnEnterPress: true,
  },
];

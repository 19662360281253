import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";

const InvoiceNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Invoice #",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number", "form_number"],
  accessorKey: "invoice_number",
  canSort: true,
  orderByField: "form_number",
  width: 83,
  dataTransform: ({ job_number, form_number }) => ({
    href: `/project/${job_number}/invoice/${form_number}`,
    text: form_number,
  }),
};

const ClientColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Client",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["client_id", "client_name"],
  accessorKey: "client",
  canSort: true,
  orderByField: "client_name",
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ client_id, client_name }) => ({
    href: `/client.php?id=${client_id}`,
    text: client_name,
  }),
  // linkAction: ({ client_id }) => console.log(client_id), // change to open clientSidePanel
};

const SubtotalColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Subtotal",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_subtotal", "currency_id"],
  accessorKey: "subtotal",
  canSort: true,
  orderByField: "total_subtotal",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ total_subtotal, currency_id }) => ({
    amount: total_subtotal,
    currency_id,
  }),
};

const ExcludedCostsColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Excluded Costs",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["excluded_costs", "currency_id"],
  accessorKey: "excluded_costs",
  canSort: true,
  orderByField: "excluded_costs",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ excluded_costs, currency_id }) => ({
    amount: excluded_costs,
    currency_id,
  }),
};

const MarginSubtotalColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Margin Subtotal",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["margin_subtotal", "currency_id"],
  accessorKey: "margin_subtotal",
  canSort: true,
  orderByField: "margin_subtotal",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ margin_subtotal, currency_id }) => ({
    amount: margin_subtotal,
    currency_id,
  }),
};

const OrderDateColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Date> = {
  header: "Order Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_orderdate"],
  accessorKey: "date_orderdate",
  canSort: true,
  orderByField: "date_orderdate",
  width: 120,
};

const InvoicePaidDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Inv Paid",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_invoice_paid"],
  accessorKey: "date_invoice_paid",
  canSort: true,
  orderByField: "date_invoice_paid",
  width: 120,
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  InvoiceNumberColumn,
  ClientColumn,
  SubtotalColumn,
  ExcludedCostsColumn,
  MarginSubtotalColumn,
  OrderDateColumn,
  InvoicePaidDateColumn,
];

import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { ClientColumn, OrderDateColumn, InvoiceNumberColumn, SubtotalColumn, TotalColumn } from "../common";

const ORepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Order Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_user_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id"
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 150,
  avatarOptions: {
    withFullName: true,
  },
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

const ProjectNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Project",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number"],
  accessorKey: "job_number",
  canSort: true,
  orderByField: "job_number",
  width: 83,
  dataTransform: ({ job_number }) => ({
    href: `/project/${job_number}`,
    text: `#${job_number}`,
  }),
};

const ProjectNameColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> =
{
  header: "Name",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_name", "job_number"],
  accessorKey: "job_name",
  canSort: true,
  orderByField: "job_name",
  width: 150,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ job_name, job_number }) => ({
    href: `/project/${job_number}`,
    text: job_name,
  }),
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ORepColumn,
  InvoiceNumberColumn,
  ProjectNumberColumn,
  ProjectNameColumn,
  ClientColumn,
  OrderDateColumn,
  SubtotalColumn,
  TotalColumn,
];

import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { EReportTypes } from "../../routes/report_types";

const currYear = new Date().getFullYear();

const SRepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "client_rep_first_name",
    "client_rep_last_name",
    "client_rep_avatar_file_id",
  ],
  accessorKey: "client_rep",
  canSort: true,
  orderByField: "client_rep",
  width: 73,
  dataTransform: ({
    client_rep_first_name,
    client_rep_last_name,
    client_rep_avatar_file_id,
  }) => ({
    full_name: `${client_rep_first_name} ${client_rep_last_name}`,
    avatar_file_id: client_rep_avatar_file_id,
  }),
};

const ClientColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Client",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["client_id", "client_name"],
  accessorKey: "client",
  canSort: true,
  orderByField: "client_name",
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ client_id, client_name }) => ({
    href: `/client.php?id=${client_id}`,
    text: client_name,
  }),
};

const IndustryColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Industry",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["industry_name"],
  accessorKey: "industry",
  canSort: true,
  orderByField: "industry_name",
  width: 136,
 textOptions: {
    truncate: true,
 },
};

const Y1Column: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: `${currYear}`,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["y1_total", "currency_id"],
  accessorKey: "y1",
  canSort: true,
  orderByField: "y1_total",
  moneyOptions: { decimalPlaces: 2 },
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ y1_total, currency_id }) => ({
    amount: y1_total,
    currency_id,
  }),
  subReportType: EReportTypes.DashboardInvoiceY1,
};

const Y2Column: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: `${currYear - 1}`,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["y2_total", "currency_id"],
  accessorKey: "y2",
  canSort: true,
  orderByField: "y2_total",
  moneyOptions: { decimalPlaces: 2 },
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ y2_total, currency_id }) => ({
    amount: y2_total,
    currency_id,
  }),
  subReportType: EReportTypes.DashboardInvoiceY2,
};

const Y3Column: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: `${currYear - 2}`,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["y3_total", "currency_id"],
  accessorKey: "y3",
  canSort: true,
  orderByField: "y3_total",
  moneyOptions: { decimalPlaces: 2 },
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ y3_total, currency_id }) => ({
    amount: y3_total,
    currency_id,
  }),
  subReportType: EReportTypes.DashboardInvoiceY3,
};

const Y4Column: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: `${currYear - 3}`,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["y4_total", "currency_id"],
  accessorKey: "y4",
  canSort: true,
  orderByField: "y4_total",
  moneyOptions: { decimalPlaces: 2 },
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ y4_total, currency_id }) => ({
    amount: y4_total,
    currency_id,
  }),
  subReportType: EReportTypes.DashboardInvoiceY4,
};

const SalesTarget: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Sales Target",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["sales_target", "currency_id"],
  accessorKey: "sales_target",
  canSort: true,
  orderByField: "client_sales_target",
  moneyOptions: { decimalPlaces: 2 },
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ sales_target, currency_id }) => ({
    amount: `${sales_target || 0}`,
    currency_id,
  }),
};

const ExceedTarget: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Exceeded Target",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["exceeded_target", "currency_id"],
  accessorKey: "sales-target-exceeded",
  canSort: true,
  orderByField: "client_sales_target_exceeded",
  moneyOptions: { decimalPlaces: 2 },
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ exceeded_target, currency_id }) => ({
    amount: `${exceeded_target || 0}`,
    currency_id,
  }),
};

const LastActivity: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.SalesTargetActivity
> = {
  header: "Last Activity",
  type: BASE_COLUMN_TYPES.SalesTargetActivity,
  fields: ["last_activity"],
  accessorKey: "last-activity",
  canSort: true,
  orderByField: "last_activity",
  width: 136,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ last_activity }) => ({
    date: last_activity?.activity_date,
    id: last_activity?.activity_id,
    type: last_activity?.activity_type,
    details: last_activity?.activity_details,
  }),
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  SRepColumn,
  ClientColumn,
  IndustryColumn,
  Y1Column,
  Y2Column,
  Y3Column,
  Y4Column,
  SalesTarget,
  ExceedTarget,
  LastActivity,
];

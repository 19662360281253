import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { SubReports } from "./sub-reports";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const SalesDashboardReportConfig: ReportConfigBase = {
  type: EReportTypes.SalesDashboard,
  endpoint: "report/sales-dashboard",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Sales Dashboard Report offers an overview of sales performance for yourself or your team.",
    url: "https://help.commonsku.com/knowledge/sales-dashboard-report",
  },
  subReports: {
    configs: SubReports,
    titleTemplate: (rowData: Record<string, any>) => "Rep Summary",
  },
};

export default SalesDashboardReportConfig;

import React from "react";
import { formatMoney, round } from "../../../../utils";

export interface CommissionExplanationProps {
  commission_source: "SUBTOTAL" | "MARGIN";
  commission_type: "ORDER" | "CLIENT" | "COMBINED";
  base_amount: number;
  base_rate: number;
  margin: number;
  margin_multiplier: number;
  sales_target_multiplier: number;
  client_multiplier: number;
  initial_amount: number;
  commission_amount: number;
  commission_override: number;
}

const CommissionExplanation = ({
  commission_source,
  commission_type,
  base_amount,
  base_rate,
  margin,
  margin_multiplier,
  sales_target_multiplier,
  client_multiplier,
  initial_amount,
  commission_amount,
  commission_override,
}: CommissionExplanationProps) => {
  const source =
    "SUBTOTAL" === commission_source ? "Order Subtotal" : "Order Margin";
  const repType =
    "ORDER" === commission_type
      ? "Order Rep"
      : "CLIENT" === commission_type
        ? "Client Rep"
        : "Order & Client Rep";
  const baseAmount = formatMoney(base_amount);
  const baseRate = round(base_rate, 2).toFixed(2);
  const totalMargin = round(margin, 0).toFixed(0);
  const marginMultiplier = round(margin_multiplier, 2).toFixed(2);
  const salesTargetMultiplier = round(sales_target_multiplier, 2).toFixed(2);
  const clientMultiplier = round(client_multiplier, 2).toFixed(2);
  const initialAmount = formatMoney(initial_amount);
  const finalAmount = formatMoney(commission_amount);

  let finalValues = null;
  if (finalAmount !== initialAmount) {
    finalValues = (
      <>
        <br />
        <br />
        <strong>
          {1 === commission_override
            ? "Final commission, manually adjusted"
            : "Final commission, modified by threshold"}
        </strong>
        <br />
        <span>${finalAmount}</span>
      </>
    );
  }

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>
              <strong>{source}</strong>
            </th>
            <th>
              <strong>{repType} Commission</strong>
            </th>
            <th>
              <strong>Margin</strong>
            </th>
            <th>
              <strong>Multiplier</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${baseAmount}</td>
            <td>{baseRate}%</td>
            <td>{totalMargin}%</td>
            <td>&times;{marginMultiplier}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <strong>Commission</strong>
      <br />
      <span>${baseAmount}</span>
      &times; <span>{baseRate}%</span>
      &times; <span>{marginMultiplier}</span>
      &times;{" "}
      {"ORDER" !== commission_type && (
        <>
          <span>{salesTargetMultiplier}</span>
          &times;{" "}
        </>
      )}
      <span>{clientMultiplier}</span>= <span>${initialAmount}</span>
      {finalValues}
    </div>
  );
};

export default CommissionExplanation;

import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const MailingListReportConfig: ReportConfigBase = {
  type: EReportTypes.MailingList,
  endpoint: "report/mailing-list",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Mailing List Report pulls details based on each contact within your client's page",
    url: "https://help.commonsku.com/knowledge/mailing-list-report-1",
  },
};

export default MailingListReportConfig;

import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Client Rep",
    type: FILTER_TYPES.User,
    fields: ["client_rep_id", "client_rep_teams", "include_inactive"],
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Client Status",
    type: FILTER_TYPES.AccountStatus,
    fields: "account_status_id",
    dropdownOptionsFilter: {
      company_type: "CLIENT",
    },
  },
  {
    label: "Industry",
    type: FILTER_TYPES.Industry,
    fields: "industry_id",
  },
  {
    label: "Client Tags",
    type: FILTER_TYPES.ClientTag,
    fields: "client_tags",
  },
];

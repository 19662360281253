import React, { useCallback } from "react";
import { useReportContext } from "../report_context";
import styled from "styled-components";
import {
  ArrowIcon,
  colors,
  Csku,
  EllipsisIcon,
  IconButton,
  Text,
  useWindowSize,
} from "@commonsku/styles";

const PAGE_NUMBER_BTN_WIDTH = 32;
const BACK_NEXT_BTN_WIDTH = 80;
const BTN_GAP = 8;
const ELLIPSIS_SURROUNDING_PAGE_COUNT = 3;
const ALWAYS_SHOW_PAGES_AT_START_AND_END = 2;

const PaginationContainer = styled(Csku)<{ hasBottomBar?: boolean; }>`
&&& {
  height: 32px;
  width: fit-content;
  margin: 60px auto ${p => p.hasBottomBar ? '100px' : '24px'} auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${BTN_GAP}px;
}`;

const PaginationButton = styled(IconButton)`
  height: 32px;
  padding: 0 8px !important;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${PAGE_NUMBER_BTN_WIDTH}px;
  width: fit-content;
`;

const ButtonText = styled(Text)`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
`;

interface PageNoButtonProps {
  page: number;
  pageNo: number;
  goToPage: (pageNo: number) => void;
}

const PageNoButton: React.FC<PageNoButtonProps> = ({
  page,
  pageNo,
  goToPage,
}: PageNoButtonProps) => (
  <PaginationButton
    variant={pageNo === page ? "primary" : "primary-light"}
    onClick={() => goToPage(page)}
  >
    <ButtonText color={pageNo === page ? "primary1" : "primary2"}>
      {page + 1}
    </ButtonText>
  </PaginationButton>
);

const Ellipsis: React.ReactNode = (
  <EllipsisIcon color={colors.neutrals["60"]} />
);

const Pagination: React.FC = () => {
  const { pageNo, pageSize, setPageNumber, firstLoaded, totalRows, reportConfig: { withSelection } } =
    useReportContext();
  const [windowWidth] = useWindowSize();

  const totalPages = Math.ceil(totalRows / pageSize);

  const paginationLayout = {
    atStart: pageNo === 0,
    atEnd: pageNo === totalPages - 1,
    totalWidth:
      BACK_NEXT_BTN_WIDTH * 2 +
      PAGE_NUMBER_BTN_WIDTH * totalPages +
      (totalPages + 1) * BTN_GAP,
  };

  const changePage = useCallback(
    (newPageNo: number) => setPageNumber(newPageNo),
    [setPageNumber],
  );

  const renderButton = useCallback(
    (iconDirection: "left" | "right", disabled: boolean, text: string) => (
      <PaginationButton
        variant="primary-light"
        Icon={<ArrowIcon direction={iconDirection} />}
        iconPosition={iconDirection}
        onClick={() =>
          disabled ||
          changePage(iconDirection === "left" ? pageNo - 1 : pageNo + 1)
        }
        disabled={disabled}
      >
        <ButtonText color={"primary1"}>{text}</ButtonText>
      </PaginationButton>
    ),
    [changePage, pageNo],
  );

  const renderPageButtons = useCallback(() => {
    const shouldTruncate = paginationLayout.totalWidth > windowWidth * 0.5;
    const pageButtons = [];

    const shouldDisplayPage = (index: number): boolean =>
      index < ALWAYS_SHOW_PAGES_AT_START_AND_END ||
      index >= totalPages - ALWAYS_SHOW_PAGES_AT_START_AND_END ||
      Math.abs(index - pageNo) <= ELLIPSIS_SURROUNDING_PAGE_COUNT;

    const shouldDisplayEllipsis = (index: number): boolean =>
      index === ALWAYS_SHOW_PAGES_AT_START_AND_END ||
      index === totalPages - ALWAYS_SHOW_PAGES_AT_START_AND_END - 1;

    const addPage = (index: number) =>
      pageButtons.push(
        <PageNoButton
          key={index}
          page={index}
          pageNo={pageNo}
          goToPage={changePage}
        />,
      );

    const addEllipsis = (key: number) =>
      pageButtons.push(
        <React.Fragment key={`ellipsis-${key}`}>{Ellipsis}</React.Fragment>,
      );

    for (let i = 0; i < totalPages; i++) {
      if (shouldTruncate) {
        if (shouldDisplayPage(i)) {
          addPage(i);
        } else if (shouldDisplayEllipsis(i)) {
          addEllipsis(i);
        }
      } else {
        addPage(i);
      }
    }

    return <>{pageButtons}</>;
  }, [
    totalPages,
    pageNo,
    paginationLayout.totalWidth,
    windowWidth,
    changePage,
  ]);

  return (
    firstLoaded &&
    totalRows > 0 && (
      <PaginationContainer hasBottomBar={withSelection}>
        {renderButton("left", paginationLayout.atStart, "Prev")}
        {renderPageButtons()}
        {renderButton("right", paginationLayout.atEnd, "Next")}
      </PaginationContainer>
    )
  );
};

export default Pagination;

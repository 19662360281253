import { EReportTypes } from "../../routes/report_types";
import {
  SubReportConfigBase,
  SubReportFilter,
  SubReportFilterSources,
} from "../../sub-report/types";

const dateFilters: SubReportFilter[] = [
  {
    field: "date_category",
    source: {
      type: SubReportFilterSources.ParentQuery,
    },
  },
  {
    field: "start_stamp",
    source: {
      type: SubReportFilterSources.ParentQuery,
    },
  },
  {
    field: "end_stamp",
    source: {
      type: SubReportFilterSources.ParentQuery,
    },
  },
  {
    field: "paid_date_category",
    source: {
      type: SubReportFilterSources.ParentQuery,
    },
  },
  {
    field: "paid_start_stamp",
    source: {
      type: SubReportFilterSources.ParentQuery,
    },
  },
  {
    field: "paid_end_stamp",
    source: {
      type: SubReportFilterSources.ParentQuery,
    },
  },
  {
    field: "from_date_created",
    source: {
      type: SubReportFilterSources.ParentQuery,
      fieldName: "start_stamp",
    },
  },
  {
    field: "to_date_created",
    source: {
      type: SubReportFilterSources.ParentQuery,
      fieldName: "end_stamp",
    },
  },
];

export const bill: SubReportConfigBase = {
  type: EReportTypes.CommissionBill,
  tabTitle: "Bills",
  defaultFilters: [
    {
      field: "order_id",
      source: {
        type: SubReportFilterSources.Row,
        columnName: "margin_costs",
      },
    },
    ...dateFilters,
  ],
};

export const invoice: SubReportConfigBase = {
  type: EReportTypes.CommissionInvoice,
  tabTitle: "Invoices",
  defaultFilters: [
    {
      field: "job_id",
      source: {
        type: SubReportFilterSources.Row,
        columnName: "margin_subtotal",
      },
    },
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "INVOICE",
      },
    },
    {
      field: "active",
      source: {
        type: SubReportFilterSources.Value,
        value: "1",
      },
    },
    ...dateFilters,
  ],
};

import React, { useCallback, useMemo, useState } from "react";
import { useReportContext } from "../report_context";
import {
  SelectionTable,
  Text,
  VirtualTable,
  VirtualTableStyles,
} from "@commonsku/styles";
import EmptyState from "../EmptyState";
import { ReportRow } from "../columns/config/ReportColumnConfig";
import { Column } from "react-table";
import "./ReportTable.css";
import { rebuildTooltip } from "../../helpers";
import Tooltip from "../../helpers/ReactTooltip";
import styled from "styled-components";
import { REPORT_TOOLTIP_TARGET_ID } from "../columns/config/types";

const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  &&& {
    padding-bottom: 16px;
  }
`;

type RowType = Record<keyof ReportRow, ReportRow[keyof ReportRow]>;
const root =
  document.getElementById("root") || document.getElementById("resku");

const DEFAULT_REPORT_WRAPPER_CLASSNAME = "default-report-table-wrapper";
const DEFAULT_REPORT_HEADER_CLASSNAME = "default-report-table-header";

const ReportTable = ({
  wrapperClassName = DEFAULT_REPORT_WRAPPER_CLASSNAME,
  headerClassName = DEFAULT_REPORT_HEADER_CLASSNAME,
}: {
  wrapperClassName?: string;
  headerClassName?: string;
}) => {
  const {
    query,
    reportConfig: {
      type,
      columns,
      withSelection,
      intro,
    },
    rowData,
    firstLoaded,
    tableHeight,
    pageSize,
    sortByColumn,
    selectedRows,
    onSelectionChange,
    onClearSelection,
    elementSizeParameters,
  } = useReportContext();
  const [sorted, setSorted] = useState<boolean>(false);

  const rowStyles = useCallback(
    ({ row, ...rest }) => {
      const style: React.CSSProperties = {};
      if (firstLoaded) {
        style.height = `${elementSizeParameters.ROW_HEIGHT}px`;
      }
      if (
        selectedRows.includes(row.original)
      ) {
        style.border = "1px solid var(--color-primary1-60)";
        style.background = "var(--color-primary1-20)";
      }
      return style;
    },
    [firstLoaded, selectedRows, elementSizeParameters.ROW_HEIGHT],
  );

  const rowGroupStyles = useCallback(
    ({ row, style }) =>
      firstLoaded
        ? {
            ...style,
            height: `${elementSizeParameters.ROW_HEIGHT}px`,
            top: `${row.index * elementSizeParameters.ROW_HEIGHT}px`,
            ...(row.index + 1 === pageSize
              ? {}
              : { borderBottom: "1px solid rgba(178, 205, 209, 0.3)" }),
          }
        : null,
    [firstLoaded, pageSize, elementSizeParameters.ROW_HEIGHT],
  );

  const tableProps = useMemo(
    () =>
      firstLoaded
        ? {
            columns: columns as unknown as Column<RowType>[],
            data: rowData,
            height: tableHeight,
            tableHeaderProps: {
              className: `report-table-header ${headerClassName}`,
            },
            itemSize: () => elementSizeParameters.ROW_HEIGHT,
            rowStyles,
            rowGroupStyles,
            useTableProps: {
              autoResetSortBy: false,
              manualSortBy: true,
            },
            onScroll: rebuildTooltip,
            onSortChange: (state) => {
              const { sortBy } = state;
              if (sortBy.length > 0) {
                setSorted(true);
                const [{ id, desc }] = sortBy;
                sortByColumn(id, desc ? "desc" : "asc");
              } else {
                sorted && sortByColumn(null, null);
              }
            },
            selectedRows,
            onSelectionChange,
          }
        : null,
    [
      firstLoaded,
      columns,
      rowData,
      tableHeight,
      rowStyles,
      rowGroupStyles,
      sortByColumn,
      sorted,
      selectedRows,
      onSelectionChange,
      headerClassName,
      elementSizeParameters.ROW_HEIGHT,
    ],
  );

  const TableComponent = useMemo(
    () => (withSelection ? SelectionTable : VirtualTable),
    [withSelection],
  );

  if (!firstLoaded) {
    return (
      <EmptyState
        reportType={type}
        description={intro.description}
        introUrl={intro.url}
      />
    );
  }

  return rowData.length > 0 ? (
    <>
      <Tooltip
        className="report-table-tooltip"
        id={REPORT_TOOLTIP_TARGET_ID}
        isCapture
      />
      <VirtualTableStyles
        className={`report-table-wrapper ${wrapperClassName}`}
        tableHeight={tableHeight}
      >
        {/* @ts-ignore */}
        <TableComponent {...tableProps} />
      </VirtualTableStyles>
    </>
  ) : <NoResults>
    <Text>No results found</Text>
  </NoResults>
};

export default ReportTable;

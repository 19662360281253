import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { ClientColumn, SupplierColumn, PurchaseOrderNumberColumn } from "../common";

const ProjectNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Project #",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number"],
  accessorKey: "job_number",
  canSort: true,
  orderByField: "job_number",
  width: 83,
  dataTransform: ({ job_number }) => ({
    href: `/project/${job_number}`,
    text: `#${job_number}`,
  }),
};

export const DateShippedColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Date Shipped",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_shipped"],
  accessorKey: "date_shipped",
  canSort: true,
  orderByField: "date_shipped",
  width: 120,
};

export const UnbilledAmountColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Unbilled Amount",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["unbilled_amount", "currency_id"],
  accessorKey: "unbilled_amount",
  canSort: true,
  orderByField: "unbilled_amount",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ unbilled_amount, currency_id }) => ({
    amount: unbilled_amount,
    currency_id,
  }),
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  PurchaseOrderNumberColumn,
  SupplierColumn,
  ClientColumn,
  UnbilledAmountColumn,
  DateShippedColumn,
  ProjectNumberColumn,
];

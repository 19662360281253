import React from "react";
import styled from "styled-components";
import SummaryItem from "./SummaryItem";
import { useReportContext } from "../report_context";
import { ReportSummaryData, SUMMARY_VALUE_TYPES } from "./types";
import { TilesSlider } from "../../helpers/TilesSlider";
import { Csku } from "@commonsku/styles";

const Container = styled(Csku)`
  background-color: #edf4f7;
  padding: 10px 0;
  margin: 20px 8px !important;

  .slide-indicator {
    padding: 0 !important;
  }
`;

const ReportSummaryContainer = () => {
  const {
    summaryData,
    reportConfig: { summary },
  } = useReportContext();

  return summary && summaryData.length > 0 ? (
    <Container>
      <TilesSlider deps={[summaryData]} sliderHeight={67}>
        {summaryData
          .filter((item: ReportSummaryData) =>
            item.type.some((type) => type !== SUMMARY_VALUE_TYPES.Hidden),
          )
          .map((item: ReportSummaryData) => (
            <SummaryItem
              key={item.label}
              data={item.data}
              type={item.type}
              label={item.label}
            />
          ))}
      </TilesSlider>
    </Container>
  ) : null;
};

export default ReportSummaryContainer;

import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const DashboardMeetingReportConfig: ReportConfigBase = {
  type: EReportTypes.DashboardMeeting,
  endpoint: "note",
  reportKey: "notes",
  filtersBase: [],
  summaryBase: [],
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Dashboard Meeting Report provides detailed information about a rep's meetings",
    url: "https://help.commonsku.com/knowledge/sales-dashboard-report",
  },
};

export default DashboardMeetingReportConfig;

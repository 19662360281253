import { ReportSummaryConfigBase } from "../../summary/types";

export const Summary: ReportSummaryConfigBase[] = [
  {
    label: "Number of Rows",
    fields: ["row_count"],
    type: ["COUNT"],
  },
  {
    label: "Current Period POs",
    fields: ["start_stamp_curr", "end_stamp_curr"],
    type: ["HIDDEN", "HIDDEN"],
  },
  {
    label: "Previous Period POs",
    fields: ["start_stamp_prev", "end_stamp_prev"],
    type: ["HIDDEN", "HIDDEN"],
  },
];

import React, { useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { colors, Csku, Text } from "@commonsku/styles";

import { EReportTypes } from "./routes/report_types";
import { document } from "../../global";
import { getReportTypeTitle } from "./routes/helpers";

const CornerGradient = styled.div`
  position: fixed;
  top: 67px;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    200deg,
    #a6f4ff -4.53%,
    rgba(168, 245, 255, 0) 16%
  );
`;

const TextContainer = styled(Csku)`
  width: 60vw;
  margin: 150px auto auto auto !important;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;

  p {
    color: ${colors.neutrals.darkest};
    font-size: 24px;
  }
  a {
    font-size: 18px;
  }
`;

interface EmptyStateProps {
  reportType: (typeof EReportTypes)[keyof typeof EReportTypes];
  description: string;
  introUrl: string;
}

interface ReportIntroProps {
  description: string;
  introUrl: string;
  reportName: string;
}

const ReportIntro: React.FC<ReportIntroProps> = ({
  description,
  introUrl,
  reportName,
}: ReportIntroProps) => (
  <TextContainer as="div">
    <Text as="p">{description}</Text>
    <a href={introUrl} target="_blank" rel="noreferrer">
      Learn More About {reportName} Report
    </a>
  </TextContainer>
);

const ArrowContainer = styled.svg<{ relative: boolean }>`
  position: absolute;
  right: 10px;
  top: ${({ relative }) => (relative ? -145 : 64)}px;
  z-index: -1;
`;

const Arrow = ({ relative = false }) => {
  return (
    <ArrowContainer
      width="633"
      height="633"
      viewBox="0 0 633 633"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      relative={relative}
    >
      <path
        d="M583.593 257.377L566.853 238.64C566.425 238.162 565.856 237.833 565.228 237.702L540.631 232.573C537.832 231.99 537.359 228.192 539.929 226.94L581.291 206.784C583.202 205.852 585.448 207.149 585.597 209.27L588.823 255.168C589.023 258.021 585.498 259.51 583.593 257.377Z"
        fill="#A6F4FF"
      />
      <path
        d="M111.773 454.9C361.998 415 509.884 336.677 575 240.5L560.998 231C538.303 284.03 312.647 321.546 92.8992 300.588L111.773 454.9Z"
        fill="url(#paint0_linear_2410_23993)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2410_23993"
          x1="572.803"
          y1="221.403"
          x2="354.573"
          y2="354.692"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6F4FF" />
          <stop offset="1" stopColor="#EDF4F7" stopOpacity="0" />
        </linearGradient>
      </defs>
    </ArrowContainer>
  );
};

const EmptyState: React.FC<EmptyStateProps> = ({
  reportType,
  description,
  introUrl,
}: EmptyStateProps) => {
  const reportName = useMemo(
    () => getReportTypeTitle(reportType),
    [reportType],
  );
  const arrowDomParent = document.getElementById("report-btn-container");

  const renderArrow = useCallback(() => {
    if (!arrowDomParent) return <Arrow />;
    return ReactDOM.createPortal(<Arrow relative />, arrowDomParent);
  }, [arrowDomParent]);

  return (
    <>
      <ReportIntro
        description={description}
        introUrl={introUrl}
        reportName={reportName}
      />
      {renderArrow()}
      {ReactDOM.createPortal(<CornerGradient />, document.body as Element)}
    </>
  );
};

export default EmptyState;

import { ReportSummaryConfigBase } from "../../summary/types";

export const Summary: ReportSummaryConfigBase[] = [
  {
    label: "Order Total",
    fields: ["total_subtotal"],
    type: ["MONEY"],
  },
  {
    label: "Number of Rows",
    fields: ["row_count"],
    type: ["COUNT"],
  },
];

import { ReportFilterConfig } from "./ReportFilterConfig";
import { DataLoader, ReportFilterConfigBase } from "./types";

export class ReportFilters {
  filterConfigs: ReportFilterConfig[];
  dependentFields: Set<string> = new Set();

  constructor(filtersBaseConfig: ReportFilterConfigBase[]) {
    this.filterConfigs = filtersBaseConfig.map(
      (c) => new ReportFilterConfig(c),
    );
    this.buildDependencies();
  }

  public loadDataForDependentFilters = (query: object, dispatch: any) => {
    const keys = Object.keys(query);
    const dataLoadersToCall: DataLoader[] = this.filterConfigs
      .map((config) => {
        if (
          config.dependsOn &&
          config.dependsOn.dataLoader &&
          config.dependsOn.filterFields.some((field) => keys.includes(field))
        ) {
          return config.dependsOn.dataLoader;
        }
        return null;
      })
      .filter((dataLoader) => dataLoader !== null);

    dataLoadersToCall.forEach((dataLoader) => {
      const args = Object.entries(query).map(
        ([key, value]) => query[key] || "",
      );
      if (args.some((arg) => arg !== "")) {
        dispatch(dataLoader(...args));
      }
    });
  };

  public initializeReportQuery = (
    filters?: ReportFilterConfig[],
    toDefault = true,
  ): Record<string, string> =>
    (filters || this.filterConfigs).reduce(
      (query, filter) => ({
        ...query,
        ...(typeof filter.fields === "string"
          ? { [filter.fields]: toDefault ? filter?.defaultValue || "" : "" }
          : Array.isArray(filter.fields)
            ? filter.fields.reduce(
                (fields, field, index) => ({
                  ...fields,
                  [field]: toDefault ? filter.defaultValue?.[index] || "" : "",
                }),
                {},
              )
            : {}),
      }),
      {},
    );

  public getSubQuery = (
    query: object,
    filters: ReportFilterConfig[],
    allowEmpty = false,
  ): Record<string, string> =>
    filters.reduce(
      (subQuery, filter) => ({
        ...subQuery,
        ...(typeof filter.fields === "string"
          ? allowEmpty
            ? { [filter.fields]: query[filter.fields] }
            : query[filter.fields]
              ? { [filter.fields]: query[filter.fields] }
              : {}
          : Array.isArray(filter.fields)
            ? filter.fields.reduce(
                (fields, field) => ({
                  ...fields,
                  ...(allowEmpty
                    ? { [field]: query[field] }
                    : query[field]
                      ? { [field]: query[field] }
                      : {}),
                }),
                {},
              )
            : {}),
      }),
      {},
    );

  public getFiltersTotalWidth = (itemGap = 13) =>
    this.filterConfigs.reduce(
      (total, filter) => total + filter.styleProps.flexBasis + itemGap,
      0,
    );

  private buildDependencies() {
    for (const config of this.filterConfigs) {
      if (config.dependsOn) {
        config.dependsOn.filterFields.forEach((field) => {
          this.dependentFields.add(field);
        });
      }
    }
  }
}

import React from "react";
import { EReportTypes } from "../routes/report_types";

export interface SubReportConfigBase {
  type: (typeof EReportTypes)[keyof typeof EReportTypes];
  defaultFilters: SubReportFilter[];
  tabTitle: React.ReactNode;
}

export const SubReportFilterSources = {
  Value: "VALUE",
  ParentQuery: "PARENT-QUERY",
  Row: "ROW",
  Summary: "SUMMARY",
} as const;

interface ValueFilter {
  type: "VALUE";
  value: string;
}
interface ParentQueryFilter {
  type: "PARENT-QUERY";
  fieldName?: string;
}
interface RowValueFilter {
  type: "ROW";
  columnName: string;
  fieldName?: string;
}

interface SummaryValueFilter {
  type: "SUMMARY";
  label: string;
  index: number;
}

export interface SubReportFilter {
  field: string;
  source: ValueFilter | ParentQueryFilter | RowValueFilter | SummaryValueFilter;
}

export interface SubReportsConfigBase {
  configs: SubReportConfigBase[];
  titleTemplate: (rowData?: Record<string, any>) => React.ReactNode;
}

export class SubReportsConfig {
  public configs: SubReportConfigBase[];
  public titleTemplate: (rowData?: Record<string, any>) => React.ReactNode;

  constructor({ configs, titleTemplate }: SubReportsConfigBase) {
    this.configs = configs;
    this.titleTemplate = titleTemplate;
  }

  public isEmpty(): boolean {
    return this.configs.length === 0;
  }

  public getConfig(
    type: (typeof EReportTypes)[keyof typeof EReportTypes],
  ): SubReportConfigBase | undefined {
    return this.configs.find((config) => config.type === type);
  }

  public getTitle(rowData: Record<string, any>): React.ReactNode {
    return this.titleTemplate(rowData);
  }
}

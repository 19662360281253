import React, { useMemo } from "react";
import { Csku, useWindowSize } from "@commonsku/styles";
import { getReportConfig } from "../config";
import {
  SubReportConfigBase,
  SubReportFilter,
  SubReportFilterSources,
} from "./types";
import { ReportProvider, useReportContext } from "../report_context";
import ReportSummaryContainer from "../summary/ReportSummaryContainer";
import ReportTable from "../table/ReportTable";
import Pagination from "../table/Pagination";
import Loading from "../Loading";

const SubReport = ({
  subReportConfig,
}: {
  subReportConfig: SubReportConfigBase;
}) => {
  const reportConfig = useMemo(
    () => getReportConfig(subReportConfig.type),
    [subReportConfig.type],
  );
  const { prevQuery, activeRow, summaryData = [] } = useReportContext();
  const defaultFilters = useMemo(
    () =>
      subReportConfig.defaultFilters.reduce(
        (acc, { field, source }: SubReportFilter) => ({
          ...acc,
          [field]:
            (source.type === SubReportFilterSources.ParentQuery
              ? prevQuery?.[source.fieldName ?? field] ?? ""
              : source.type === SubReportFilterSources.Row
                ? activeRow?.[source.columnName]?.[source.fieldName ?? field] ??
                  ""
                : source.type === SubReportFilterSources.Summary
                  ? summaryData.find((sd) => sd.label === source.label)?.data[
                      source.index
                    ] ?? ""
                  : source.value) ?? "",
        }),
        {},
      ),
    [prevQuery, activeRow, subReportConfig],
  );
  const windowHeight = useWindowSize()[1];

  return (
    <Csku
      as={"div"}
      className="csku-styles"
      style={{ overflow: "auto", marginTop: "8px" }}
    >
      <ReportProvider
        reportConfig={reportConfig}
        defaultFilters={defaultFilters}
        containerHeight={windowHeight}
      >
        <ReportSummaryContainer />
        <ReportTable
          wrapperClassName="subreport-table-wrapper"
          headerClassName="subreport-table-header"
        />
        <Pagination />
        <Loading />
      </ReportProvider>
    </Csku>
  );
};

export default SubReport;

import { dateRangePickerFields } from "../../filters/components/DateRangePicker";
import { FILTER_TYPES, ReportFilterConfigBase } from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
    {
        label: "Project",
        fields: "job_name_or_number",
        type: FILTER_TYPES.ClientPageProjectQuery,
        fetchReportOnEnterPress: true,
        showLabel: false,
    },
    {
        label: "Date",
        type: FILTER_TYPES.DateRange,
        fields: dateRangePickerFields,
        defaultValue: ["this_year", "", ""],
        placeholder: "Any date",
        showLabel: false,
    },
    {
        label: "Stage",
        type: FILTER_TYPES.Dropdown,
        fields: "order_type",
        options: [
            { value: "", label: "Any" },
            { value: "OPPORTUNITY", label: "Opportunity" },
            { value: "PRESENTATION", label: "Presentation" },
            { value: "ESTIMATE", label: "Estimate" },
            { value: "SALES ORDER", label: "Sales Order" },
            { value: "INVOICE", label: "Invoice" },
        ],
        placeholder: "Any stage",
        showLabel: false,
    },
    {
        label: "Rep",
        type: FILTER_TYPES.User,
        fields: ["sales_rep_id", "sales_rep_teams", "include_inactive_order_reps"],
        placeholder: "Any rep",
        showLabel: false,
    },
    {
        label: "Show Deleted",
        fields: "show_deleted",
        type: FILTER_TYPES.Checkbox,
        placeholder: "Show Deleted",
        showLabel: false,
    },
];

import { ReportSummaryConfigBase } from "../../summary/types";

const currYear = new Date().getFullYear();

export const Summary: ReportSummaryConfigBase[] = [
  {
    label: `${currYear} Total`,
    fields: ["y1_total"],
    type: ["MONEY"],
  },
  {
    label: `${currYear - 1} Total`,
    fields: ["y2_total"],
    type: ["MONEY"],
  },
  {
    label: `${currYear - 2} Total`,
    fields: ["y3_total"],
    type: ["MONEY"],
  },
  {
    label: `${currYear - 3} Total`,
    fields: ["y4_total"],
    type: ["MONEY"],
  },
  {
    label: "Sales Target Total",
    fields: ["sales_target"],
    type: ["MONEY"],
  },
  {
    label: "Exceeded Target Total",
    fields: ["exceeded_target"],
    type: ["MONEY"],
  },
  {
    label: "Number of Rows",
    fields: ["row_count"],
    type: ["COUNT"],
  },
];

import { BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";

import {
  OpportunityNumberColumn,
  ClientColumn,
  ProjectColumn,
  BudgetColumn,
  OrderDateColumn,
} from "../common";

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  OpportunityNumberColumn,
  ClientColumn,
  ProjectColumn,
  BudgetColumn,
  OrderDateColumn,
];

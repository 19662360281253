import React, { useEffect, useMemo, useState } from "react";
import { useReportContext } from "../../report_context";
import {
  Button,
  Col,
  LabeledInput,
  LabeledSelect,
  LabeledTextarea,
  Popup,
  Row,
  Typography,
  XIcon,
} from "@commonsku/styles";
import { formatMoney, oauth, round } from "../../../../utils";

type TCommissionForm = {
  commission_id: string;
  order_number: string | number;
  commission_source: string;
  total_margin_amount: string | number;
  margin_subtotal: string | number;
  commission_rate: string | number;
  commission_amount: string | number;
  commission_notes: string;
};
type OverrideCommissionPopupProps = {
  commissionId: string;
  onClose: () => void;
};
const CommissionSourceOptions = [
  { label: "Margin", value: "MARGIN" },
  { label: "Subtotal", value: "SUBTOTAL" },
];
const OverrideCommissionPopup = ({
  commissionId,
  onClose,
}: OverrideCommissionPopupProps) => {
  const { fetchReport } = useReportContext();
  const [state, setState] = useState<TCommissionForm>({
    commission_id: commissionId,
    order_number: "",
    commission_source: "",
    total_margin_amount: "",
    margin_subtotal: "",
    commission_rate: "",
    commission_amount: "",
    commission_notes: "",
  });

  const onUpdateField = (field: keyof TCommissionForm, value: any) =>
    setState((s) => ({ ...s, [field]: value }));

  const onSave = () => {
    oauth("PUT", `commission/${commissionId}`, {
      commission_source: state.commission_source,
      commission_rate: state.commission_rate,
      commission_amount: state.commission_amount,
      commission_notes: state.commission_notes,
    })
      .then(() => {
        fetchReport();
        onClose();
      })
      .catch(() => {
        // todo: implement
      });
  };

  useEffect(() => {
    if (!commissionId) {
      return;
    }
    oauth("GET", `commission/${commissionId}`, {}).then(({ json }) => {
      setState(json.commission);
    });
  }, [commissionId]);

  const calculateCommission = (
    rate?: string | number,
    amount?: string | number,
  ) => {
    const commission_source = state.commission_source;
    const margin =
      commission_source === "MARGIN"
        ? +state.total_margin_amount
        : +state.margin_subtotal;

    if (typeof amount !== "undefined") {
      amount = parseFloat(amount + "") || 0;
      rate = (amount * 100) / margin;
    } else if (typeof rate !== "undefined") {
      rate = parseFloat(rate + "") || 0;
      amount = (rate * margin) / 100;
    } else {
      rate = +state.commission_rate || 0;
      amount = (rate * margin) / 100;
    }

    return {
      commission_rate: round(rate, 2),
      commission_amount: round(amount, 2),
    };
  };

  return (
    <Popup
      height="526px"
      width="549px"
      header={
        <Row>
          <Col xs sm={8}>
            <Typography.H2>Override Commission</Typography.H2>
          </Col>
          <Col xs sm={4} style={{ textAlign: "right" }}>
            <XIcon style={{ cursor: "pointer" }} onClick={() => onClose()} />
          </Col>
        </Row>
      }
      onClose={onClose}
      style={{ padding: 16 }}
    >
      <Row style={{ gap: "8px", overflowY: "auto" }}>
        <Col xs>
          <LabeledInput
            label="Sales Order #"
            defaultValue={state.order_number}
            readOnly
          />
        </Col>
        <Col xs sm={5.8}>
          <Typography.Text as={"label"}>
            Commissionable Subtotal
          </Typography.Text>
          <Typography.Text as={"label"}>
            {state.margin_subtotal
              ? `$${formatMoney(state.margin_subtotal)}`
              : ""}
          </Typography.Text>
        </Col>
        <Col xs sm={5.8}>
          <Typography.Text as={"label"}>Margin Amount</Typography.Text>
          <Typography.Text as={"label"}>
            {state.total_margin_amount
              ? `$${formatMoney(state.total_margin_amount)}`
              : ""}
          </Typography.Text>
        </Col>
        <Col xs sm={3.85}>
          <LabeledSelect
            inPopup
            label="Source"
            options={CommissionSourceOptions}
            value={CommissionSourceOptions.find(
              (v) => v.value === state.commission_source,
            )}
            onChange={(e) => {
              onUpdateField("commission_source", e.value);
              setState((s) => ({ ...s, ...calculateCommission() }));
            }}
            isClearable={false}
          />
        </Col>
        <Col xs sm={3.85} style={{ marginTop: "5px" }}>
          <LabeledInput
            label="Rate"
            value={state.commission_rate}
            onChange={(e) => {
              onUpdateField("commission_rate", e.target.value);
              setState((s) => ({
                ...s,
                commission_rate: e.target.value,
              }));
            }}
            onBlur={(e) => {
              onUpdateField("commission_rate", e.target.value);
              setState((s) => ({
                ...s,
                ...calculateCommission(e.target.value),
              }));
            }}
          />
        </Col>
        <Col xs sm={3.85} style={{ marginTop: "5px" }}>
          <LabeledInput
            label="Amount"
            value={state.commission_amount}
            onChange={(e) => {
              onUpdateField("commission_amount", e.target.value);
              setState((s) => ({
                ...s,
                commission_amount: e.target.value,
              }));
            }}
            onBlur={(e) => {
              onUpdateField("commission_amount", e.target.value);
              setState((s) => ({
                ...s,
                ...calculateCommission(undefined, e.target.value),
              }));
            }}
          />
        </Col>
        <Col xs>
          <LabeledTextarea
            label="Notes"
            style={{ height: "90px" }}
            value={state.commission_notes}
            onChange={(e) => onUpdateField("commission_notes", e.target.value)}
          />
        </Col>
        <Col xs sm={6} smOffset={6}>
          <Row style={{ textAlign: "right", gap: "8px" }}>
            <Col xs sm={5.8}>
              <Button
                variant="secondary"
                size="large"
                style={{ width: "100%" }}
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Col>
            <Col xs sm={5.8}>
              <Button
                variant="primary"
                size="large"
                style={{ width: "100%" }}
                onClick={() => onSave()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Popup>
  );
};

export default OverrideCommissionPopup;

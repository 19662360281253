import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const SupplierSpendReportConfig: ReportConfigBase = {
  type: EReportTypes.SupplierSpend,
  endpoint: "report/supplier-spend",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Supplier spend comparative report measures the amount you spend per supplier based on your purchase orders. Learn more about how to pull a Supplier spend report.",
    url: "https://help.commonsku.com/knowledge/supplier-spend-report",
  },
};

export default SupplierSpendReportConfig;

import { dateRangePickerFields } from "../../filters/components/DateRangePicker";
import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Order Date",
    type: FILTER_TYPES.DateRange,
    fields: dateRangePickerFields,
    defaultValue: ["this_year", "", ""],
  },
  {
    label: "Export Date",
    type: FILTER_TYPES.DateRange,
    fields: ["export_date_category", "export_start_stamp", "export_end_stamp"],
    defaultValue: ["this_year", "", ""],
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Order Rep",
    type: FILTER_TYPES.User,
    fields: ["sales_rep_id", "sales_rep_teams", "include_inactive_order_reps"],
  },
  {
    label: "Project",
    fields: "job_name_or_number",
    type: FILTER_TYPES.Query,
    fetchReportOnEnterPress: true,
  },
];

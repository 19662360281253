import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const SalesRepReportConfig: ReportConfigBase = {
  type: EReportTypes.SalesRep,
  endpoint: "report/sales-rep",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Sales Rep Report offers various reporting options, like average margin, highest margin, and year to date sales",
    url: "https://help.commonsku.com/knowledge/sales-rep-report-1",
  },
};

export default SalesRepReportConfig;

import { ReportSummaryConfigBase } from "../../summary/types";

export const Summary: ReportSummaryConfigBase[] = [
  {
    label: "# Sales Orders",
    fields: ["num_sales_orders"],
    type: ["COUNT"],
  },
  {
    label: "# Sales Orders in Production",
    fields: ["num_sales_orders_in_production"],
    type: ["COUNT"],
  },
  {
    label: "# Invoices",
    fields: ["num_invoices"],
    type: ["COUNT"],
  },
  {
    label: "# Purchase Orders",
    fields: ["num_purchase_orders"],
    type: ["COUNT"],
  },
  {
    label: "Sales Order Total",
    fields: ["total_sales_orders"],
    type: ["MONEY"],
  },
  {
    label: "In Production Total",
    fields: ["total_in_production"],
    type: ["MONEY"],
  },
  {
    label: "Invoice Total",
    fields: ["total_invoices"],
    type: ["MONEY"],
  },
  {
    label: "Total In Production Margin",
    fields: ["margin_in_production"],
    type: ["MONEY"],
  },
  {
    label: "Dates",
    fields: ["start_stamp", "end_stamp"],
    type: ["HIDDEN", "HIDDEN"],
  },
  {
    label: "Number of Rows",
    fields: ["row_count"],
    type: ["COUNT"],
  },
];

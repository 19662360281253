import { Filters } from "./filters";
import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const ClientProjectReportConfig: ReportConfigBase = {
  type: EReportTypes.ClientProject,
  endpoint: "client/projects",
  filtersBase: Filters,
  summaryBase: [],
  columnsBase: Columns,
  withSelection: false,
  resultsPerPage: 50,
  refetchOnFilterChange: true,
  scrollOnFilterChange: false,
  intro: {
    description:
      "The Client Projects Report shows all the projects for a client",
    url: "https://help.commonsku.com/knowledge/client",
  },
};

export default ClientProjectReportConfig;

import { dateRangePickerFields } from "../../filters/components/DateRangePicker";
import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Rep Type",
    type: FILTER_TYPES.Switch,
    fields: "sales_rep_type",
    options: [
      { label: "Client Rep", value: "CLIENT" },
      { label: "Order Rep", value: "ORDER" },
    ],
    defaultValue: "ORDER",
    flexBasis: 250,
    containerStyle: {
      minWidth: "250px",
    },
  },
  {
    label: "Rep",
    type: FILTER_TYPES.User,
    fields: ["sales_rep_id", "sales_rep_teams", "include_inactive"],
  },
  {
    label: "Date",
    type: FILTER_TYPES.DateRange,
    fields: dateRangePickerFields,
    defaultValue: ["this_year", "", ""],
  },
];

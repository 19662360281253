import { ReportSummaryConfigBase } from "../../summary/types";

export const Summary: ReportSummaryConfigBase[] = [
  {
    label: "Deposit Total",
    fields: ["deposit_subtotal"],
    type: ["MONEY"],
  },
  {
    label: "Amount Received Total",
    fields: ["deposit_received_subtotal"],
    type: ["MONEY"],
  },
  {
    label: "Number of Rows",
    fields: ["row_count"],
    type: ["COUNT"],
  },
];

import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";

import {
  ClientColumn,
  DateCreatedColumn,
} from "../common";

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ClientColumn,
  DateCreatedColumn,
];

import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";
import { dateRangePickerFields } from "../../filters/components/DateRangePicker";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Client Rep",
    type: FILTER_TYPES.User,
    fields: [
      "sales_rep_id",
      "sales_rep_teams",
      "include_inactive",
    ],
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Client Tags",
    type: FILTER_TYPES.ClientTag,
    fields: "client_tags",
  },
  {
    label: "Industry",
    type: FILTER_TYPES.Industry,
    fields: "industry_id",
  },
  {
    label: "Client Status",
    type: FILTER_TYPES.AccountStatus,
    fields: "account_status_id",
    dropdownOptionsFilter: {
      company_type: "CLIENT",
    }
  },
  {
    label: "Department",
    type: FILTER_TYPES.Department,
    fields: "department_id",
  },
  {
    label: "Country",
    type: FILTER_TYPES.Country,
    fields: "address_country",
  },
  {
    label: "Province/State",
    type: FILTER_TYPES.Province,
    fields: "address_state",
    dependsOn: {
      filterFields: ["address_country"],
    },
  },
  {
    label: "Contact Status",
    type: FILTER_TYPES.Dropdown,
    fields: "contact_status",
    options: [
      { value: "", label: "Any" },
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "Inactive" },
    ],
  },
  {
    label: "Contact Tags",
    type: FILTER_TYPES.ContactTag,
    fields: "contact_tags",
  },
  {
    label: "Revenue By",
    type: FILTER_TYPES.Dropdown,
    fields: "revenue_field",
    options: [
      { value: "", label: "None" },
      { value: "billing_contact_id", label: "Billing Contact" },
      { value: "shipping_contact_id", label: "Shipping Contact" },
    ],
  },
  {
    label: "Date",
    type: FILTER_TYPES.DateRange,
    fields: dateRangePickerFields,
  },
  {
    label: "OK to send marketing?",
    type: FILTER_TYPES.Switch,
    fields: "no_marketing",
    options: [
      { label: "Yes", value: "0" },
      { label: "No", value: "1" },
      { label: "Any", value: "" },
    ],
    flexBasis: 228,
    containerStyle: {
      minWidth: "230px",
    },
    defaultValue: "0",
  },
];

import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";

import {
  PurchaseOrderNumberColumn,
  SupplierColumn,
  ProjectColumn,
  SubtotalColumn,
  DateCreatedColumn
} from "../common";

const StatusColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Status",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["status_name"],
  accessorKey: "status_name",
  canSort: true,
  orderByField: "status_name",
  width: 95,
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  PurchaseOrderNumberColumn,
  SupplierColumn,
  ProjectColumn,
  SubtotalColumn,
  StatusColumn,
  DateCreatedColumn,
];

import { EReportTypes } from "../../routes/report_types";
import {
  SubReportConfigBase,
  SubReportFilter,
  SubReportFilterSources,
} from "../../sub-report/types";

const dateFilters: SubReportFilter[] = [
  {
    field: "from_date_orderdate",
    source: {
      type: SubReportFilterSources.Summary,
      label: "Dates",
      index: 0,
    },
  },
  {
    field: "to_date_orderdate",
    source: {
      type: SubReportFilterSources.Summary,
      label: "Dates",
      index: 1,
    },
  },
];

const SalesOrderSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardSalesOrder,
  tabTitle: "Sales Orders",
  defaultFilters: [
    {
      field: "sales_rep_id",
      source: {
        type: SubReportFilterSources.Row,
        columnName: "sales_rep",
      },
    },
    {
      field: "sales_rep_type",
      source: {
        type: SubReportFilterSources.ParentQuery,
      },
    },
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "SALES ORDER",
      },
    },
    {
      field: "active",
      source: {
        type: SubReportFilterSources.Value,
        value: "1",
      },
    },
    ...dateFilters,
  ],
};

const InProductionSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardInProduction,
  tabTitle: "In Production",
  defaultFilters: [
    {
      field: "sales_rep_id",
      source: {
        type: SubReportFilterSources.Row,
        columnName: "sales_rep",
      },
    },
    {
      field: "sales_rep_type",
      source: {
        type: SubReportFilterSources.ParentQuery,
      },
    },
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "SALES ORDER",
      },
    },
    {
      field: "active",
      source: {
        type: SubReportFilterSources.Value,
        value: "1",
      },
    },
    {
      field: "from_date_in_production",
      source: {
        type: SubReportFilterSources.Summary,
        label: "Dates",
        index: 0,
      },
    },
    {
      field: "to_date_in_production",
      source: {
        type: SubReportFilterSources.Summary,
        label: "Dates",
        index: 1,
      },
    },
  ],
};

const PurchaseOrderSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardPurchaseOrder,
  tabTitle: "Purchase Orders",
  defaultFilters: [
    {
      field: "created_by",
      source: {
        type: SubReportFilterSources.Row,
        columnName: "sales_rep",
        fieldName: "sales_rep_id",
      },
    },
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "INVOICE",
      },
    },
    {
      field: "active",
      source: {
        type: SubReportFilterSources.Value,
        value: "1",
      },
    },
    {
      field: "from_date_created",
      source: {
        type: SubReportFilterSources.Summary,
        label: "Dates",
        index: 0,
      },
    },
    {
      field: "to_date_created",
      source: {
        type: SubReportFilterSources.Summary,
        label: "Dates",
        index: 1,
      },
    },
  ],
};

const InvoiceSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardInvoice,
  tabTitle: "Invoices",
  defaultFilters: [
    {
      field: "sales_rep_id",
      source: {
        type: SubReportFilterSources.Row,
        columnName: "sales_rep",
      },
    },
    {
      field: "sales_rep_type",
      source: {
        type: SubReportFilterSources.ParentQuery,
      },
    },
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "INVOICE",
      },
    },
    {
      field: "active",
      source: {
        type: SubReportFilterSources.Value,
        value: "1",
      },
    },
    ...dateFilters,
  ],
};

export const SubReports: SubReportConfigBase[] = [
  SalesOrderSubReport,
  InProductionSubReport,
  InvoiceSubReport,
  PurchaseOrderSubReport,
];

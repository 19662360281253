import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";

import {
  InvoiceNumberColumn,
  ClientColumn,
  ProjectColumn,
  SubtotalColumn,
  OrderDateColumn,
} from "../common";

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  InvoiceNumberColumn,
  ClientColumn,
  ProjectColumn,
  SubtotalColumn,
  OrderDateColumn,
];

import React, { ReactNode } from "react";
import ColumnHeader from "../ColumnHeader";
import {
  AvatarOptions,
  BASE_COLUMN_TYPES,
  BaseColumnType,
  ColumnDataMap,
  MoneyOptions,
  PercentageOptions,
  TextOptions,
  DEFAULT_SORT_DESC,
} from "./types";
import Cell from "../components/Cell";
import { ReportTypeValues } from "../../routes/report_types";

export class ReportColumnConfigBase<T extends BaseColumnType> {
  /**
   * Represents the base configuration for a column in a report.
   *
   * @class ReportColumnConfigBase
   * @property {string} header - The header text to be displayed for the column.
   * @property {BaseColumnType} type - The type of the column, determining how it should be rendered and handled.
   * @property {string[]} fields - The fields from the data source that this column will use.
   * @property {string} accessorKey - The key that uniquely identifies the column in the data source.
   * @property {boolean} canSort - Indicates if the column can be sorted.
   * @property {string} [orderByField] - Optional. The field in the data source to sort by if different from the accessor key.
   * @property {string} [sortDescFirst] - Optional. Whether the column sort in descending order by default.
   * @property {Object} [avatarOptions] - Optional. Additional options for avatar-type columns.
   * @property {boolean} [avatarOptions.withFullName] - Indicates whether to display the full name next to the avatar.
   * @property {boolean} [noDrag] - Optional. Indicates whether the column should be draggable or not.
   * @property {Function} [dataTransform] - Optional. A function to transform the data before rendering.
   * @property {Function} [linkAction] - Optional. An action to be executed when a link in the column is clicked.
   * the input of dataTransform and linkAction is the value of the field in the data source.
   */
  header: React.ReactNode;
  type: T;
  fields: string[];
  accessorKey: string;
  canSort: boolean;
  canResize?: boolean;
  orderByField?: string;
  sortDescFirst?: boolean;
  avatarOptions?: AvatarOptions;
  moneyOptions?: MoneyOptions;
  percentageOptions?: PercentageOptions;
  textOptions?: TextOptions;
  noDrag?: boolean;
  dataTransform?: (data: any) => ColumnDataMap<T>;
  linkAction?: (data: any) => any;
  subReportType?: ReportTypeValues;
  width: number;
  justify?: "flex-start" | "flex-end" | "center";
}

interface ReactTableColumn {
  Header: () => null | React.ReactNode;
  accessor: any;
  id: string;
  Cell: () => null | React.ReactNode;
  width: number;
  minWidth: number;
  toggleEditing?: any;
  disableSortBy: boolean;
  sortDescFirst: boolean;
}

export class ReportColumnConfig<T extends BaseColumnType>
  extends ReportColumnConfigBase<T>
  implements ReactTableColumn
{
  accessorKey: string;
  avatarOptions: { withFullName?: boolean };
  fields: string[];
  header: React.ReactNode;
  orderByField: string;
  canSort: boolean;
  disableSortBy: boolean;
  sortDescFirst: boolean = false;
  canResize: boolean = true;
  type: T;
  justify: "flex-start" | "flex-end" | "center";
  id: string;
  accessor: any;
  Header: () => ReactNode;
  noDrag: boolean;
  width: number;
  minWidth: number;
  configuredWidth: number;
  Cell: any;
  toggleEditing?: any;
  dataTransform?: (data: any) => ColumnDataMap<T>;
  linkAction?: (data: any) => any;
  className?: string;
  style?: React.CSSProperties;
  subReportType?: ReportTypeValues;

  constructor({
    header,
    type,
    fields,
    accessorKey,
    canSort,
    orderByField,
    sortDescFirst,
    avatarOptions,
    moneyOptions,
    textOptions = {},
    percentageOptions,
    noDrag = true,
    canResize = true,
    dataTransform,
    linkAction,
    width,
    justify,
    subReportType,
  }: ReportColumnConfigBase<T>) {
    super();
    this.header = header;
    this.type = type;
    this.fields = fields;
    this.accessorKey = accessorKey;
    this.accessor = accessorKey;
    this.avatarOptions = avatarOptions || { withFullName: false };
    this.moneyOptions = moneyOptions || { decimalPlaces: 0, colorful: false };
    this.textOptions = textOptions || {};
    this.percentageOptions = percentageOptions || {
      decimalPlaces: 0,
      includeTrendline: false,
    };
    this.noDrag = noDrag;
    this.dataTransform = dataTransform;
    this.linkAction = linkAction;
    this.canResize = canResize;
    this.id = orderByField;
    this.Header = () => <ColumnHeader label={this.header} />;
    this.justify = justify ?? this.setJustify();
    this.className = `column-${this.justify}`;
    this.setCell();
    this.setSort(type, canSort, orderByField, sortDescFirst);
    this.configuredWidth = width;
    this.minWidth = width;
    this.width = width;
    this.subReportType = subReportType;
  }

  private setJustify = () => {
    switch (this.type) {
      // icons and avatars cells
      case BASE_COLUMN_TYPES.Avatar:
        if (this.avatarOptions?.withFullName) {
          return "flex-start";
        }
        return "center";
      case BASE_COLUMN_TYPES.Notes:
      case BASE_COLUMN_TYPES.Quantity:
        return "center";
      // numeric values cells
      case BASE_COLUMN_TYPES.Money:
      case BASE_COLUMN_TYPES.Percentage:
        return "flex-end";
      // alphanumeric values cells
      case BASE_COLUMN_TYPES.Link:
      default:
        return "flex-start";
    }
  };

  private setCell = () => (this.Cell = Cell(this.type));

  private setSort = (type: T, canSort: boolean, orderByField?: string, sortDescFirst?: boolean) => {
    this.canSort = canSort;
    this.disableSortBy = !canSort;
    if (canSort) {
      if (!orderByField) {
        throw new Error("orderByField is required when canSort is true");
      } else {
        this.orderByField = orderByField;
      }
      this.sortDescFirst = sortDescFirst ?? DEFAULT_SORT_DESC[type] ?? false;
    }
  };

  public setToggleEditing = (toggleEditing: any) =>
    (this.toggleEditing = toggleEditing);
}

export const getFullColumnConfig = (
  reportColumnConfigBase: ReportColumnConfigBase<BaseColumnType>[],
): ReportColumnConfig<BaseColumnType>[] =>
  reportColumnConfigBase.map((config) => new ReportColumnConfig(config));

export class ReportRow {
  [key: string]: string | number | boolean | object | undefined;

  constructor(
    rawRowData: object,
    configs: ReportColumnConfig<BaseColumnType>[],
  ) {
    configs.forEach((config) => {
      const { accessorKey, fields } = config;
      this[accessorKey] = fields.reduce((acc, field) => {
        return { ...acc, [field]: rawRowData[field] };
      }, {});
    });
  }
}

export const mapRowDataByConfig = (
  rawRows: object[],
  config: ReportColumnConfig<BaseColumnType>[],
): ReportRow[] => rawRows.map((row) => new ReportRow(row, config));

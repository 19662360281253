import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const DashboardAccountReportConfig: ReportConfigBase = {
  type: EReportTypes.DashboardEstimate,
  endpoint: "client",
  reportKey: "clients",
  filtersBase: [],
  summaryBase: [],
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Dashboard Account Report provides detailed information about a rep's new accounts",
    url: "https://help.commonsku.com/knowledge/sales-dashboard-report",
  },
};

export default DashboardAccountReportConfig;

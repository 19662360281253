import React, { useMemo } from "react";
import { Popup, Tabs, TTab } from "@commonsku/styles";
import { BASE_ZINDEX } from "../../../popup-factory";
import { PopupHeaderWithCloseIcon } from "../action-menu/LoadReportPopup";
import { ReportTypeValues } from "../routes/report_types";
import { SubReportConfigBase } from "./types";
import { useReportContext } from "../report_context";
import SubReport from "./SubReport";

export interface SubReportPopupProps {
  title: React.ReactNode;
  subReportType: ReportTypeValues;
  onClose: () => void;
}

const SubReportPopup = ({
  title,
  subReportType,
  onClose,
}: SubReportPopupProps) => {
  const { reportConfig, setSubReportType } = useReportContext();

  const tabs = useMemo<TTab[]>(
    () =>
      reportConfig.subReports.configs.map(
        (sr: SubReportConfigBase, index: number) => ({
          label: sr.tabTitle,
          content: (
            <div key={index}>
              <SubReport subReportConfig={sr} />
            </div>
          ),
          onClick: () => setSubReportType(sr.type),
        }),
      ),
    [reportConfig.subReports.configs],
  );

  const activeIndex = useMemo<number>(
    () =>
      reportConfig.subReports.configs.findIndex(
        (src) => src.type === subReportType,
      ),
    [subReportType, reportConfig.subReports.configs],
  );

  return (
    <Popup
      closeOnClickOutside
      overlayZIndex={BASE_ZINDEX + 100}
      zIndex={BASE_ZINDEX + 200}
      style={{ width: "80vw", height: "60vh", overflow: "hidden" }}
      header={<PopupHeaderWithCloseIcon title={title} onPopupClose={onClose} />}
      onClose={onClose}
    >
      <Tabs tabs={tabs} selectedTabIndex={activeIndex} />
    </Popup>
  );
};

export default SubReportPopup;

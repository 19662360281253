import React from "react";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { Typography } from "@commonsku/styles";
import { getImageSrc } from "../../../../utils";

const ClientRepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Client Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "client_rep_user_id",
    "client_rep_first_name",
    "client_rep_last_name",
    "client_rep_avatar_file_id",
  ],
  accessorKey: "client_rep",
  canSort: true,
  orderByField: "client_rep",
  width: 50,
  dataTransform: ({
    client_rep_first_name,
    client_rep_last_name,
    client_rep_avatar_file_id,
  }) => ({
    full_name: `${client_rep_first_name} ${client_rep_last_name}`,
    avatar_file_id: client_rep_avatar_file_id,
  }),
};

const OrderRepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Order Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_user_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id",
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 50,
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};


const ITEM_IMAGE_STYLE = { maxWidth: 45, maxHeight: 45 };

const ProjectDetailsColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "Project",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["job_number", "job_name", "items"],
  accessorKey: "job",
  canSort: true,
  orderByField: "job_number",
  width: 250,
  dataTransform: ({ job_name, job_number, items }) => {
    const href = `/project/${job_number}`;
    const text = `#${job_number}: ${job_name}`;
    const itemsToShow = items.slice(0, 5);
    return {
      cell: (
        <div>
          <Typography.Span
            as="a"
            style={{
              display: "block",
              alignContent: "left",
              color: "var(--color-primary1-main)",
            }}
            href={href}
            target="_blank"
            rel="noreferrer"
            title={text}
          >
            {text}
          </Typography.Span>
	  {itemsToShow.map((item) => (
            <img
              key={item.item_id}
              data-for={"report-table-tooltip"}
              data-tip={item.item_name}
              alt={item.item_name}
              src={getImageSrc(item)}
              style={ITEM_IMAGE_STYLE}
            />
          ))}
        </div>
      )
    };
  },
};

function getOrderHref(job_number, order_type, form_number) {
  if ('OPPORTUNTIY' === order_type) {
    return `/project/${job_number}`;
  }
  return `/project/${job_number}/${order_type.toLowerCase().replaceAll(' ', '-')}/${form_number}`;
}

const StatusColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Status> = {
  header: "Stage",
  type: BASE_COLUMN_TYPES.Status,
  fields: ["order_type", "status_name", "form_number", "job_number", "filter_orders"],
  accessorKey: "form",
  canSort: true,
  orderByField: "order_status",
  width: 130,
  dataTransform: ({ order_type, status_name, form_number, job_number, filter_orders }) => ({
    order_type,
    status_name,
    form_number,
    href: getOrderHref(job_number, order_type, form_number),
    filter_orders: filter_orders.map(
      fo => ({
        order_type: fo.order_type,
        form_number: fo.form_number,
        href: getOrderHref(job_number, fo.order_type, fo.form_number)
      })
    )
  }),
};

const BudgetSubtotalColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Budget/Total",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["budget_subtotal"],
  accessorKey: "budget_subtotal",
  canSort: true,
  orderByField: "budget_subtotal",
  width: 75,
  moneyOptions: { decimalPlaces: 0 },
  dataTransform: ({ budget_subtotal, currency_id }) => ({
    amount: budget_subtotal,
    currency_id,
  }),
};

const InHandsDateColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Date> = {
  header: "In-Hands Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_inhandsdate"],
  accessorKey: "date_inhandsdate",
  canSort: true,
  orderByField: "date_inhandsdate",
  width: 100,
};

const OrderDateColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Date> = {
  header: "Order Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_orderdate"],
  accessorKey: "date_orderdate",
  canSort: true,
  orderByField: "date_orderdate",
  width: 100,
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ClientRepColumn,
  OrderRepColumn,
  ProjectDetailsColumn,
  StatusColumn,
  BudgetSubtotalColumn,
  InHandsDateColumn,
  OrderDateColumn,
];

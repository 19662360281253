import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const CommissionBillReportConfig: ReportConfigBase = {
  type: EReportTypes.CommissionBill,
  endpoint: "bill",
  reportKey: "bills",
  filtersBase: [],
  summaryBase: [],
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Commission Bill Report provides detailed information about the bills used in the calculation of a commission",
    url: "https://help.commonsku.com/knowledge/commission-report",
  },
};

export default CommissionBillReportConfig;

import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { SubReports } from "./sub-reports";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const SalesTargetReportConfig: ReportConfigBase = {
  type: EReportTypes.SalesTarget,
  endpoint: "report/sales-target",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Sales Target Report measures each client's YTD sales, YOY sales, and the most recent activity",
    url: "https://help.commonsku.com/knowledge/sales-target-report-1",
  },
  subReports: {
    configs: SubReports,
    titleTemplate: (rowData: Record<string, any>) => `Invoices for ${rowData.client.client_name}`,
  },
};

export default SalesTargetReportConfig;

import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const FinanceInvoicesReportConfig: ReportConfigBase = {
  type: EReportTypes.FinanceInvoices,
  endpoint: "report/invoices",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: true,
  intro: {
    description:
      "Invoices",
    url: "https://help.commonsku.com/knowledge/client-invoicing-reports",
  },
};

export default FinanceInvoicesReportConfig;

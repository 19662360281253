import React, { useMemo } from "react";
import styled from "styled-components";
import { colors } from "@commonsku/styles";

const Header = styled.div`
  color: ${colors.neutrals.bodyText};
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
`;

const ColumnHeader = ({ label }: { label: React.ReactNode }) => {
  const { lines, title } = useMemo(() => {
    if (typeof label === "string") {
      return {
        lines: label
          .split("\n")
          .map((line, index) => <div key={index}>{line}</div>),
        title: label,
      };
    }
    return { lines: label };
  }, [label]);

  return <Header title={title}>{lines}</Header>;
};

export default ColumnHeader;

import { ReportSummaryConfigBase } from "../../summary/types";

export const Summary: ReportSummaryConfigBase[] = [
  {
    label: "# Opportunities",
    fields: ["opportunity_count"],
    type: ["COUNT"],
  },
  {
    label: "# Presentations",
    fields: ["presentation_count"],
    type: ["COUNT"],
  },
  {
    label: "# Estimates",
    fields: ["estimate_count"],
    type: ["COUNT"],
  },
  {
    label: "Total Opportunity Budget",
    fields: ["total_opportunity_budget"],
    type: ["MONEY"],
  },
  {
    label: "Total Presentation Budget",
    fields: ["total_presentation_budget"],
    type: ["MONEY"],
  },
  {
    label: "Total Estimate Budget",
    fields: ["total_estimate_budget"],
    type: ["MONEY"],
  },
  {
    label: "Dates",
    fields: ["start_stamp", "end_stamp"],
    type: ["HIDDEN", "HIDDEN"],
  },
  {
    label: "Number of Rows",
    fields: ["row_count"],
    type: ["COUNT"],
  },
];

import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const DashboardInvoiceReportConfig: ReportConfigBase = {
  type: EReportTypes.DashboardInvoice,
  endpoint: "order",
  reportKey: "orders",
  filtersBase: [],
  summaryBase: [],
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "The Dashboard Invoice Report provides detailed information about a rep's invoices",
    url: "https://help.commonsku.com/knowledge/sales-dashboard-report",
  },
};

export default DashboardInvoiceReportConfig;

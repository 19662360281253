import Status from "../../../../constants/Status";
import { dateRangePickerFields } from "../../filters/components/DateRangePicker";
import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

const stageOptions = [
  { label: "Open", value: "OPEN" },
  { label: "In Production", value: Status.SALES_ORDER_IN_PRODUCTION },
  { label: "Shipped", value: Status.SALES_ORDER_SHIPPED },
  { label: "Ready To Be Invoiced", value: Status.SALES_ORDER_READY_TO_BE_INVOICED },
];

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Stage",
    type: FILTER_TYPES.GenericSelect,
    options: stageOptions,
    fields: "status_id",
    defaultValue: "OPEN",
  },
  {
    label: "Order Date",
    type: FILTER_TYPES.DateRange,
    fields: dateRangePickerFields,
    defaultValue: ["this_year", "", ""],
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Order Rep",
    type: FILTER_TYPES.User,
    fields: ["sales_rep_id", "sales_rep_teams", "include_inactive_order_reps"],
  },
  {
    label: "Project",
    fields: "job_name_or_number",
    type: FILTER_TYPES.Query,
    fetchReportOnEnterPress: true,
  },
];

import { dateRangePickerFields } from "../../filters/components/DateRangePicker";
import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Rep",
    type: FILTER_TYPES.User,
    fields: ["sales_rep_id", "sales_rep_teams", "include_inactive"],
  },

  {
    label: "Invoice Date",
    type: FILTER_TYPES.DateRange,
    fields: dateRangePickerFields,
  },
  {
    label: "Invoice Paid Date",
    type: FILTER_TYPES.DateRange,
    fields: ["invoice_paid_date_category", "invoice_paid_start_stamp", "invoice_paid_end_stamp"],
  },
  {
    label: "Date Paid",
    type: FILTER_TYPES.DateRange,
    fields: ["paid_date_category", "paid_start_stamp", "paid_end_stamp"],
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Rep Type",
    type: FILTER_TYPES.Switch,
    fields: "sales_rep_type",
    options: [
      { label: "Both", value: "" },
      { label: "Order", value: "ORDER" },
      { label: "Client", value: "CLIENT" },
    ],
    flexBasis: 278,
    containerStyle: {
      minWidth: "298px",
    },
  },
  {
    label: "Invoices",
    type: FILTER_TYPES.Switch,
    fields: "invoice_paid",
    options: [
      { label: "Both", value: "" },
      { label: "Paid", value: "paid" },
      { label: "Unpaid", value: "unpaid" },
    ],
    flexBasis: 228,
    containerStyle: {
      minWidth: "230px",
    },
  },
  {
    label: "Bills",
    type: FILTER_TYPES.Switch,
    fields: "bills_paid",
    options: [
      { label: "Both", value: "" },
      { label: "Paid", value: "paid" },
      { label: "Unpaid", value: "unpaid" },
    ],
    flexBasis: 228,
    containerStyle: {
      minWidth: "230px",
    },
  },
  {
    label: "Commissions",
    type: FILTER_TYPES.Switch,
    fields: "commission_paid",
    options: [
      { label: "Both", value: "" },
      { label: "Paid", value: "paid" },
      { label: "Unpaid", value: "unpaid" },
    ],
    flexBasis: 228,
    containerStyle: {
      minWidth: "230px",
    },
  },
];

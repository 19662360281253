import React from "react";
import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { BulkActions } from "./bulk-actions";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";
import { bill, invoice } from "./sub-reports";

const CommissionsReportConfig: ReportConfigBase = {
  type: EReportTypes.Commission,
  endpoint: "report/commission",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: true,
  intro: {
    description:
      "The Commission Report offers various reporting options, like rep, rep type, client, invoice/paid date range, paid or unpaid status on invoices, bills and commissions",
    url: "https://help.commonsku.com/knowledge/commission-report",
  },
  bulkActions: BulkActions,
  subReports: {
    configs: [bill, invoice],
    titleTemplate: (rowData: Record<string, any>) =>
      `Project #${rowData.job.job_number}`,
  },
};

export default CommissionsReportConfig;

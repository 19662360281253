import { dateRangePickerFields } from "../../filters/components/DateRangePicker";
import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Shipping Date",
    type: FILTER_TYPES.DateRange,
    fields: dateRangePickerFields,
    defaultValue: ["this_year", "", ""],
  },
  {
    label: "Supplier",
    type: FILTER_TYPES.Supplier,
    fields: "division_id",
  },
  {
    label: "Purchase Order",
    type: FILTER_TYPES.Query,
    fields: "po_form_number",
    fetchReportOnEnterPress: true,
  },
  {
    label: "Project",
    type: FILTER_TYPES.Query,
    fields: "job_name_or_number",
    fetchReportOnEnterPress: true,
  },
];

import React from "react";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { formatDateFromString } from "../../../../utils";
import { ReportSummaryData } from "../../summary/types";
import { useReportContext } from "../../report_context";

const SUBHEADER_STYLE = {
  fontWeight: "normal",
  fontSize: "12px",
  fontFamily: "skufont-regular, sans-serif"
};

const DateColumnHeader = ({ name }) => {
  const { summaryData, prevQuery } = useReportContext();
  const summary = summaryData.find(d => d.label === name);
  if (!summary) {
    return name;
  }
  const year = formatDateFromString(summary.data[0], { year: "numeric", locales: "en-US" });
  const month = formatDateFromString(summary.data[0], { month: "long", locales: "en-US" });
  const quarter = `Q${Math.ceil((new Date(summary.data[0]).getMonth() + 0.5) / 3)}`;
  const header = "M" === prevQuery["interval"]
    ? `${month} ${year}`
    : ("Q" === prevQuery["interval"]
      ? `${quarter} ${year}`
      : ("Y" === prevQuery["interval"]
        ? year
        : name));
  const subheader = 1 === +prevQuery["proration"]
    ? formatDateFromString(summary.data[1], { month: "long", day: "numeric", locales: "en-US" })
    : null;
  return (
    <div title={`${header} Invoices ${subheader ? `(Up to ${subheader})` : ''}`}>
      <div>{header} Invoices</div>
      {subheader && <span style={SUBHEADER_STYLE}>Up to {subheader}</span>}
    </div>
  );
};

const RepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Rep Name",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: ["rep_first_name", "rep_last_name", "rep_image_id"],
  accessorKey: "rep_id",
  canSort: true,
  orderByField: "rep_id",
  width: 344,
  avatarOptions: {
    withFullName: true,
  },
  dataTransform: ({
    rep_first_name,
    rep_last_name,
    rep_image_id,
  }) => ({
    full_name: `${rep_first_name} ${rep_last_name}`,
    avatar_file_id: rep_image_id,
  }),
};

const CurrentColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: <DateColumnHeader name="Current Period Invoices" />,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["fromPeriod"],
  accessorKey: "fromPeriod",
  canSort: true,
  orderByField: "fromPeriod",
  width: 303,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ fromPeriod }) => ({
    amount: fromPeriod,
    currency_id: 'USD',
  }),
};

const PreviousColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: <DateColumnHeader name="Previous Period Invoices" />,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["toPeriod"],
  accessorKey: "toPeriod",
  canSort: true,
  orderByField: "toPeriod",
  width: 303,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ toPeriod }) => ({
    amount: toPeriod,
    currency_id: 'USD',
  }),
};

const ChangeColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Change",
  type: BASE_COLUMN_TYPES.Money,
  moneyOptions: { colorful: true, decimalPlaces: 2 },
  fields: ["fromPeriod", "toPeriod"],
  accessorKey: "change",
  canSort: true,
  orderByField: "change",
  width: 150,
  dataTransform: ({ fromPeriod, toPeriod }) => {
    const change = parseFloat(fromPeriod) - parseFloat(toPeriod);
    return {
      amount: String(change),
      currency_id: 'USD',
    };
  }
};

const ChangePercentageColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Percentage
> = {
  header: "Change %",
  type: BASE_COLUMN_TYPES.Percentage,
  percentageOptions: { includeTrendline: true, decimalPlaces: 2 },
  fields: ["fromPeriod", "toPeriod"],
  accessorKey: "change_percentage",
  canSort: false,
  orderByField: "change_percentage",
  width: 150,
  dataTransform: ({ fromPeriod, toPeriod }) => {
    const change = parseFloat(fromPeriod) - parseFloat(toPeriod);
    let percentage = Math.round(change / parseFloat(toPeriod) * 100);
    if (0 !== parseFloat(toPeriod)) {
      percentage = parseFloat(percentage.toFixed(2));
    }
    if (isNaN(percentage)) {
      percentage = Infinity;
    }
    if (0 === change) {
      percentage = 0;
    }
    return {
      percentage: percentage
    };
  }
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  RepColumn,
  CurrentColumn,
  PreviousColumn,
  ChangeColumn,
  ChangePercentageColumn,
];

import { EReportTypes } from "../../routes/report_types";
import {
  SubReportConfigBase,
  SubReportFilter,
  SubReportFilterSources,
} from "../../sub-report/types";

const orderDefaultFilters: SubReportFilter[] = [
  {
    field: "from_date_orderdate",
    source: {
      type: SubReportFilterSources.Summary,
      label: "Dates",
      index: 0,
    },
  },
  {
    field: "to_date_orderdate",
    source: {
      type: SubReportFilterSources.Summary,
      label: "Dates",
      index: 1,
    },
  },
  {
    field: "sales_rep_id",
    source: {
      type: SubReportFilterSources.Row,
      columnName: "sales_rep",
    },
  },
  {
    field: "sales_rep_type",
    source: {
      type: SubReportFilterSources.ParentQuery,
    },
  },
  {
    field: "active",
    source: {
      type: SubReportFilterSources.Value,
      value: "1",
    },
  },
];

const noteDefaultFilters: SubReportFilter[] = [
  {
    field: "from_date_created",
    source: {
      type: SubReportFilterSources.Summary,
      label: "Dates",
      index: 0,
    },
  },
  {
    field: "to_date_created",
    source: {
      type: SubReportFilterSources.Summary,
      label: "Dates",
      index: 1,
    },
  },
  {
    field: "created_by",
    source: {
      type: SubReportFilterSources.Row,
      columnName: "sales_rep",
      fieldName: "sales_rep_id",
    },
  },
  {
    field: "parent_type",
    source: {
      type: SubReportFilterSources.Value,
      value: "CLIENT",
    },
  },
  {
    field: "active",
    source: {
      type: SubReportFilterSources.Value,
      value: "1",
    },
  },
];

const NewAccountSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardAccount,
  tabTitle: "New Accounts",
  defaultFilters: [
    {
      field: "from_date_created",
      source: {
        type: SubReportFilterSources.Summary,
        label: "Dates",
        index: 0,
      },
    },
    {
      field: "to_date_created",
      source: {
        type: SubReportFilterSources.Summary,
        label: "Dates",
        index: 1,
      },
    },
    {
      field: "sales_rep_id",
      source: {
        type: SubReportFilterSources.Row,
        columnName: "sales_rep",
      },
    },
    {
      field: "active",
      source: {
        type: SubReportFilterSources.Value,
        value: "1",
      },
    },
  ],
};

const NoteSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardNote,
  tabTitle: "Notes",
  defaultFilters: [
    {
      field: "note_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "NOTE",
      },
    },
    ...noteDefaultFilters,
  ],
};

const CallSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardCall,
  tabTitle: "Calls",
  defaultFilters: [
    {
      field: "note_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "CALL",
      },
    },
    ...noteDefaultFilters,
  ],
};

const MeetingSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardMeeting,
  tabTitle: "Meetings",
  defaultFilters: [
    {
      field: "note_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "MEETING",
      },
    },
    ...noteDefaultFilters,
  ],
};

const OpportunitySubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardOpportunity,
  tabTitle: "Opportunties",
  defaultFilters: [
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "OPPORTUNITY",
      },
    },
    ...orderDefaultFilters,
  ],
};

const PresentationSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardPresentation,
  tabTitle: "Presentations",
  defaultFilters: [
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "PRESENTATION",
      },
    },
    ...orderDefaultFilters,
  ],
};

const EstimateSubReport: SubReportConfigBase = {
  type: EReportTypes.DashboardEstimate,
  tabTitle: "Estimates",
  defaultFilters: [
    {
      field: "order_type",
      source: {
        type: SubReportFilterSources.Value,
        value: "ESTIMATE",
      },
    },
    ...orderDefaultFilters,
  ],
};

export const SubReports: SubReportConfigBase[] = [
  NewAccountSubReport,
  OpportunitySubReport,
  PresentationSubReport,
  EstimateSubReport,
  NoteSubReport,
  CallSubReport,
  MeetingSubReport,
];

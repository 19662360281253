export const SUMMARY_VALUE_TYPES = {
  Count: "COUNT",
  Money: "MONEY",
  Percentage: "PERCENTAGE",
  Hidden: "HIDDEN",
} as const;

export type SummaryValues =
  (typeof SUMMARY_VALUE_TYPES)[keyof typeof SUMMARY_VALUE_TYPES];

export interface SummaryOptions {
  decimalPlaces?: number;
}

export interface ReportSummaryConfigBase {
  fields: string[];
  options?: SummaryOptions[];
  label: string;
  type: SummaryValues[]; // order should follow fields
}

export interface ReportSummaryData extends ReportSummaryConfigBase {
  data: string[];
}

const formatters: Record<
  SummaryValues,
  (value: string | number, options?: SummaryOptions) => string
> = {
  [SUMMARY_VALUE_TYPES.Count]: (value: string, options?: SummaryOptions) =>
    value || "0",
  [SUMMARY_VALUE_TYPES.Money]: (value: string, options?: SummaryOptions) =>
    !!value
      ? `$${Intl.NumberFormat("en-US", {
          minimumFractionDigits: options?.decimalPlaces ?? 2,
          maximumFractionDigits: options?.decimalPlaces ?? 2,
        }).format(parseFloat(value))}`
      : "$0",
  [SUMMARY_VALUE_TYPES.Percentage]: (value: number, options?: SummaryOptions) =>
    `${value.toFixed(options?.decimalPlaces ?? 2)}%`,
  [SUMMARY_VALUE_TYPES.Hidden]: (value: string, options?: SummaryOptions) =>
    value,
};

export class ReportSummaryConfig implements ReportSummaryConfigBase {
  fields: string[];
  options?: SummaryOptions[];
  label: string;
  type: SummaryValues[];

  constructor({ fields, options, label, type }: ReportSummaryConfigBase) {
    this.fields = fields;
    this.options = options ?? [];
    this.label = label;
    this.type = type;
  }

  public getValue(data: (string | number)[]): string[] {
    return data.map((v, i) => formatters[this.type[i]](v, this.options[i]));
  }
}

export const getFullSummaryConfig = (
  summaryConfigBase: ReportSummaryConfigBase[],
) => summaryConfigBase.map((config) => new ReportSummaryConfig(config));

export const mapSummaryDataByConfig = (
  data: { [key: string]: string | number },
  summaryConfig: ReportSummaryConfig[],
): ReportSummaryData[] =>
  summaryConfig.map((config) => {
    const { fields } = config;
    const value = fields.map((field) => data[field]);

    return {
      ...config,
      data: config.getValue(value),
    };
  });

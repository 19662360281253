import React from "react";
import { formatDateFromString } from "../../../../utils";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { ReportSummaryData } from "../../summary/types";
import { useReportContext } from "../../report_context";

const SupplierColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Supplier Name",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["division_name", "supplier_id", "division_id"],
  accessorKey: "division_name",
  canSort: true,
  orderByField: "division_name",
  width: 344,
  dataTransform: ({ division_name, supplier_id, division_id }) => ({
    text: `${division_name}`,
    href: `/vendor.php?id=${supplier_id}&division_id=${division_id}`,
  }),
};

const SUBHEADER_STYLE = {
  fontWeight: "normal",
  fontSize: "12px",
  fontFamily: "skufont-regular, sans-serif",
};

const DateColumnHeader = ({ name }) => {
  const { summaryData, prevQuery } = useReportContext();
  const summary = summaryData.find((d) => d.label === name);
  if (!summary) {
    return name;
  }

  const year = formatDateFromString(summary.data[0], {
    year: "numeric",
    locales: "en-US",
  });
  const month = formatDateFromString(summary.data[0], {
    month: "long",
    locales: "en-US",
  });
  const quarter = `Q${Math.ceil((new Date(summary.data[0]).getMonth() + 0.5) / 3)}`;
  const header =
    "M" === prevQuery["interval"]
      ? `${month} ${year}`
      : "Q" === prevQuery["interval"]
        ? `${quarter} ${year}`
        : "Y" === prevQuery["interval"]
          ? year
          : name;
  const subheader =
    1 === +prevQuery["proration"]
      ? formatDateFromString(summary.data[1], {
          month: "long",
          day: "numeric",
          locales: "en-US",
        })
      : null;

  return (
    <div title={`${header} POs ${subheader ? `(Up to ${subheader})` : ""}`}>
      <div>{header} POs</div>
      {subheader && <span style={SUBHEADER_STYLE}>Up to {subheader}</span>}
    </div>
  );
};

const CurrentColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: <DateColumnHeader name="Current Period POs" />,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["fromPeriod"],
  accessorKey: "fromPeriod",
  canSort: true,
  orderByField: "fromPeriod",
  width: 303,
  dataTransform: ({ fromPeriod }) => ({
    amount: fromPeriod,
    currency_id: "USD",
  }),
};

const PreviousColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: <DateColumnHeader name="Previous Period POs" />,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["toPeriod"],
  accessorKey: "toPeriod",
  canSort: true,
  orderByField: "toPeriod",
  width: 303,
  dataTransform: ({ toPeriod }) => ({
    amount: toPeriod,
    currency_id: "USD",
  }),
};

const ChangeColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Change",
  type: BASE_COLUMN_TYPES.Money,
  moneyOptions: { colorful: true },
  fields: ["fromPeriod", "toPeriod"],
  accessorKey: "change",
  canSort: true,
  orderByField: "change",
  width: 150,
  dataTransform: ({ fromPeriod, toPeriod }) => {
    const change = parseFloat(fromPeriod) - parseFloat(toPeriod);
    return {
      amount: String(change),
      currency_id: "USD",
    };
  },
};

const ChangePercentageColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Percentage
> = {
  header: "Change %",
  type: BASE_COLUMN_TYPES.Percentage,
  percentageOptions: { includeTrendline: true },
  fields: ["fromPeriod", "toPeriod"],
  accessorKey: "change_percentage",
  canSort: false,
  orderByField: "change_percentage",
  width: 150,
  dataTransform: ({ fromPeriod, toPeriod }) => {
    const change = parseFloat(fromPeriod) - parseFloat(toPeriod);
    let percentage = Math.round((change / parseFloat(toPeriod)) * 100);
    if (0 !== parseFloat(toPeriod)) {
      percentage = parseFloat(percentage.toFixed(2));
    }
    if (isNaN(percentage)) {
      percentage = Infinity;
    }
    if (0 === change) {
      percentage = 0;
    }
    return {
      percentage: percentage,
    };
  },
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  SupplierColumn,
  CurrentColumn,
  PreviousColumn,
  ChangeColumn,
  ChangePercentageColumn,
];
